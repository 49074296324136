.hire-resources-banner {
    background: url(../assets/img/hire-resource/banner-hire-resource.png) no-repeat center top;
    height: 1000px;
    padding: 8% 0px 5%;
    display: block;
}

.hire-resources-content {
    text-align: left;
    color: #fff;
    padding: 0px 0px;
    width: 35%;
    float: right;
    margin-right: 4%;
}

.hire-resources-content h1 {
    font-size: 45px;
    line-height: 45px;
    margin-bottom: 20px;
}

.hire-resources-content p {
    font-size: 24px;
    line-height: 34px;
}

#hire-form {
    margin: 20px 0px 0px;
}

#hire-form input {
    height: 55px;
    background: #fff;
    border-radius: 10px;
    border: solid 1px #dcdcdc;
    color: #333333;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 12px;
    font-family: 'Onest', sans-serif;
}

#hire-form select {
    height: 55px;
    background: #fff;
    border-radius: 10px;
    border: solid 1px #dcdcdc;
    color: #333333;
    font-weight: 400;
    margin-bottom: 12px;
    font-family: 'Onest', sans-serif;
    font-size: 16px;
}

#hire-form textarea {
    background: #fff;
    border-radius: 10px;
    border: solid 1px #dcdcdc;
    color: #333333;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 15px;
    font-family: 'Onest', sans-serif;
}

#hire-form .form-control::placeholder {
    color: #333333;
    opacity: 1;
    font-weight: 400;
    font-family: 'Onest', sans-serif;
    font-size: 16px;
}

#hire-form .btn-orange {
    background-color: #03DAC4;
    color: #fff;
    width: 215px;
    height: 60px;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    border-radius: 30px;
    margin-top: 20px;
    float: left;
}

#hire-form .btn-orange:hover {
    background-color: #0F3828;
}

#hire-companyname-error,
#hire-email-error,
#hire-resource-required-error,
#hire-required-techstacks-error,
#hire-about-product-error,
#hire_phone_num-error {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 5px;
    display: block;
}

.hire-resources-banner-content {
    text-align: left;
    color: #fff;
    padding: 0px 0px;
    width: 40%;
    float: left;
    margin-left: 0%;
    margin-top: 40%;
}

.hire-resources-banner-content h1 {
    font-size: 45px;
    line-height: 45px;
    margin-bottom: 20px;
}

.hire-resources-banner-content p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
}

.hire-resources-empower-team {
    padding: 0% 0px 0%;
}

.hire-resources-empower-team-bg {
    background: url(../assets/img/hire-resource/team-bg.png) no-repeat left bottom;
    min-height: 450px;
    background-size: contain;
    position: relative;
}

.hire-resources-empower-team-bg h4 {
    font-size: 22px;
    position: absolute;
    left: 33px;
    bottom: 45px;
    font-weight: 700;
}

.hire-resources-empower-team-content {
    padding: 15% 0px 0%;
}

.hire-resources-empower-team-content h2 {
    text-align: left;
    font-size: 33px;
    margin-bottom: 30px;
}

.hire-resources-empower-team-content h2 span {
    color: #03DAC4;
}

.hire-resources-empower-team-content p {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 30px;
    font-weight: 400;
}

.hire-innovations-resources .tabs-container {
    position: relative;
    padding: 0% 0px 0%;
    margin: auto;
}

.hire-innovations-resources .tabs-container nav {
    max-width: 100%;
    background: #EBF2F9;
}

.hire-innovations-resources .tabs-container nav ul {
    list-style: none;
    margin: 0 auto;
    border: 0px solid #ccc;
    padding: 0 10px;
    display: flex;
    line-height: 50px;
    flex-direction: row;
    justify-content: center;
    white-space: nowrap;
}

.hire-innovations-resources .nav-link {
    border: none;
    background: #EBF2F9 !important;
    color: #333333;
    font-size: 17px;
}

.hire-innovations-resources .nav-link.active {
    color: #03DAC4;
}

.hire-tab-content-bg {
    background: url(../assets/img/hire-resource/tab-bg-1.png) no-repeat center top;
}

.hire-innovations-resources .tab-content .tab-description {
    text-align: left;
    color: #fff;
    background: rgba(51, 51, 51, 0.8);
    width: 45%;
    padding: 60px 50px 20px;
}

.hire-innovations-resources .tab-content .tab-description h3 {
    color: #fff;
    font-size: 30px;
    margin-bottom: 30px;
    font-weight: 700;
}

.hire-innovations-resources .tab-content .tab-description p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    padding-bottom: 20px;
}

.hire-innovations-resources .tab-content {
    display: inherit;
    padding: 10% 5% 0px;
}

.hire-innovations-resources li.nav-item {
    display: inline-block;
    margin: 0px 2rem;
    padding: 10px 10px 0.2rem 10px;
    border: none;
}

.our-expert-team {
    padding: 5% 0px 5%;
    margin: 0px auto;
    text-align: center;
}

.our-expert-team h3 {
    color: #333333;
    font-size: 30px;
    margin-bottom: 30px;
}

.our-expert-team .flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
}

.our-expert-team .flex-item {
    padding: 5px;
    width: auto;
    height: 167px;
    margin-top: 10px;
    line-height: 167px;
    color: white;
    font-weight: bold;
    font-size: 3em;
    text-align: center;
}

.our-expert-team p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    padding-bottom: 20px;
    color: #333333;
}




@media only screen and (min-width: 992px) and (max-width: 1024.99px) {

    .hire-resources-banner-content {
        text-align: left;
        color: #fff;
        padding: 0;
        width: 40%;
        float: left;
        margin-left: 0%;
        margin-top: 60%;
    }

    .hire-resources-content {
        text-align: left;
        color: #fff;
        padding: 0;
        width: 40%;
        float: right;
        margin-right: 4%;
        margin-top: 2%;
    }

    .hire-resources-content h1 {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 20px;
    }


    .hire-resources-empower-team-bg h4 {
        font-size: 22px;
        font-family: 'Onest', sans-serif;
        position: absolute;
        left: 33px;
        bottom: 7px;
        font-weight: 700;
    }

    .hire-resources-empower-team-content h2 {
        text-align: left;
        font-size: 33px;
        margin-bottom: 30px;
    }

    li.nav-item {
        display: inline-block;
        margin: 0px 5px;
        padding: 10px 0px .2rem;
        border: none;
    }

    .hire-innovations-resources .nav-link {
        font-size: 17px;
    }


}

@media only screen and (min-width: 768px) and (max-width: 991.99px) {

    .hire-resources-banner-content {
        text-align: left;
        color: #fff;
        padding: 0;
        width: 40%;
        float: left;
        margin-left: 0%;
        margin-top: 60%;
    }

    .hire-resources-content {
        text-align: left;
        color: #fff;
        padding: 0;
        width: 45%;
        float: right;
        margin-right: 4%;
        margin-top: 4%;
    }

    .hire-resources-content h1 {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 20px;
    }


    .hire-resources-empower-team-bg h4 {
        font-size: 22px;
        font-family: 'Onest', sans-serif;
        position: absolute;
        left: 33px;
        bottom: 7px;
        font-weight: 700;
    }

    .hire-resources-empower-team-content h2 {
        text-align: left;
        font-size: 33px;
        margin-bottom: 30px;
    }

    li.nav-item {
        display: inline-block;
        margin: 0px 5px;
        padding: 10px 0px .2rem;
        border: none;
    }

    .hire-innovations-resources .nav-link {
        font-size: 17px;
    }

    .hire-innovations-resources .tab-content .tab-description {
        text-align: left;
        color: #fff;
        background: rgba(51, 51, 51, .8);
        width: 65%;
        padding: 60px 50px 20px;
    }

    .our-expert-team p br {
        display: none;
    }

}



@media only screen and (min-width: 560px) and (max-width: 767.99px) {


    .hire-resources {
        background: #fff;
        border-radius: 30px;
        font-size: 13px;
        line-height: 25px;
        text-align: center;
        text-decoration: none;
        padding: 15px 5px;
        color: #000;
        font-weight: 700;
        width: 175px;
        margin-left: 20px;
    }


    .hire-resources-banner-content {
        text-align: left;
        color: #fff;
        padding: 0px 0px;
        width: 100%;
        float: left;
        margin-left: 0%;
        margin-top: 5%;
    }

    .hire-resources-banner-content h1 {
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 20px;

    }

    .hire-resources-banner-content p {
        font-size: 18px;
        line-height: 30px;
        font-weight: 300;
        font-family: 'Onest', sans-serif;
    }



    .hire-resources-banner {
        background-color: #000;
        background-position: right top;
        height: 1300px;
    }

    .hire-resources-empower-team-bg {
        background-position: center top;

    }

    .hire-resources-empower-team-bg h4 {
        font-size: 22px;
        font-family: 'Onest', sans-serif;
        position: unset;
        margin-top: 71%;
        margin-left: 18%;
        font-weight: 700;
    }

    .hire-resources-content {
        text-align: left;
        color: #fff;
        padding: 0px 0px;
        width: 100%;
        float: right;
        margin-right: 0%;
        margin-top: 13%;
    }

    .hire-resources-content h1 {
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 20px;
    }

    .hire-resources-empower-team-content h2 {
        text-align: left;
        font-size: 30px;
        margin-bottom: 30px;
    }

    .hire-resources-empower-team-content h2 br {
        display: none;
    }

    .hire-innovations-resources .tab-content .tab-description h3 {
        color: #fff;
        font-size: 25px;
        margin-bottom: 30px;
        font-weight: 700;
    }

    .hire-innovations-resources .nav-link {
        font-size: 17px;
    }



    .hire-innovations-resources .tab-content .tab-description {
        text-align: left;
        color: #fff;
        background: rgba(51, 51, 51, .8);
        width: auto;
        padding: 20px 30px 20px;
        margin-bottom: 40px;
    }


    .our-expert-team p br {
        display: none;
    }

}



@media only screen and (min-width: 460px) and (max-width: 559.99px) {

    .hire-resources-banner-content {
        text-align: left;
        color: #fff;
        padding: 0px 0px;
        width: 100%;
        float: left;
        margin-left: 0%;
        margin-top: 5%;
    }

    .hire-resources-banner-content h1 {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 20px;

    }

    .hire-resources-banner-content p {
        font-size: 18px;
        line-height: 30px;
        font-weight: 300;
        font-family: 'Onest', sans-serif;
    }


    .hire-resources-banner {
        background-color: #000;
        background-position: right top;
        height: 1300px;
    }

    .hire-resources-empower-team-bg {
        background-position: center top;

    }

    .hire-resources-empower-team-bg h4 {
        font-size: 22px;
        font-family: 'Onest', sans-serif;
        position: unset;
        margin-top: 71%;
        margin-left: 18%;
        font-weight: 700;
    }

    .hire-resources-content {
        text-align: left;
        color: #fff;
        padding: 0px 0px;
        width: 100%;
        float: right;
        margin-right: 0%;
        margin-top: 13%;
    }

    .hire-resources-content h1 {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 20px;
    }

    .hire-resources-empower-team-content h2 {
        text-align: left;
        font-size: 30px;
        margin-bottom: 30px;
    }

    .hire-resources-empower-team-content h2 br {
        display: none;
    }

    .hire-innovations-resources .tab-content .tab-description h3 {
        color: #fff;
        font-size: 25px;
        margin-bottom: 30px;
        font-weight: 700;
    }

    .hire-innovations-resources .nav-link {
        font-size: 17px;
    }



    .hire-innovations-resources .tab-content .tab-description {
        text-align: left;
        color: #fff;
        background: rgba(51, 51, 51, .8);
        width: auto;
        padding: 20px 30px 20px;
        margin-bottom: 40px;
    }


    .our-expert-team p br {
        display: none;
    }

}

@media only screen and (min-width: 360px) and (max-width: 459.99px) {

    .hire-resources-banner-content {
        text-align: left;
        color: #fff;
        padding: 0px 0px;
        width: 100%;
        float: left;
        margin-left: 0%;
        margin-top: 5%;
    }

    .hire-resources-banner-content h1 {
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 20px;

    }

    .hire-resources-banner-content p {
        font-size: 18px;
        line-height: 30px;
        font-weight: 300;
        font-family: 'Onest', sans-serif;
    }


    .hire-resources-banner {
        background-color: #000;
        background-position: right top;
        height: 1300px;
    }

    .hire-resources-empower-team-bg {
        background-position: center top;

    }

    .hire-resources-empower-team-bg h4 {
        font-size: 22px;
        font-family: 'Onest', sans-serif;
        position: unset;
        margin-top: 71%;
        margin-left: 18%;
        font-weight: 700;
    }

    .hire-resources-content {
        text-align: left;
        color: #fff;
        padding: 0px 0px;
        width: 100%;
        float: right;
        margin-right: 0%;
        margin-top: 13%;
    }

    .hire-resources-content h1 {
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 20px;
    }

    .hire-resources-empower-team-content h2 {
        text-align: left;
        font-size: 30px;
        margin-bottom: 30px;
    }

    .hire-resources-empower-team-content h2 br {
        display: none;
    }

    .hire-innovations-resources .tab-content .tab-description h3 {
        color: #fff;
        font-size: 25px;
        margin-bottom: 30px;
        font-weight: 700;
    }

    .hire-innovations-resources .nav-link {
        font-size: 17px;
    }



    .hire-innovations-resources .tab-content .tab-description {
        text-align: left;
        color: #fff;
        background: rgba(51, 51, 51, .8);
        width: auto;
        padding: 20px 30px 20px;
        margin-bottom: 40px;
    }


    .our-expert-team p br {
        display: none;
    }

}

@media only screen and (min-width: 320px) and (max-width: 359.99px) {
    .hire-resources-banner-content {
        text-align: left;
        color: #fff;
        padding: 0px 0px;
        width: 100%;
        float: left;
        margin-left: 0%;
        margin-top: 5%;
    }

    .hire-resources-banner-content h1 {
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 20px;

    }

    .hire-resources-banner-content p {
        font-size: 18px;
        line-height: 30px;
        font-weight: 300;
        font-family: 'Onest', sans-serif;
    }

    .hire-resources-banner {
        background-color: #000;
        background-position: right top;
        height: 1300px;
    }

    .hire-resources-empower-team-bg {
        background-position: center top;

    }

    .hire-resources-empower-team-bg h4 {
        font-size: 22px;
        font-family: 'Onest', sans-serif;
        position: unset;
        margin-top: 71%;
        margin-left: 18%;
        font-weight: 700;
    }

    .hire-resources-content {
        text-align: left;
        color: #fff;
        padding: 0px 0px;
        width: 100%;
        float: right;
        margin-right: 0%;
        margin-top: 15%;
    }

    .hire-resources-content h1 {
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 20px;
    }

    .hire-resources-empower-team-content h2 {
        text-align: left;
        font-size: 30px;
        margin-bottom: 30px;
    }

    .hire-resources-empower-team-content h2 br {
        display: none;
    }

    .hire-innovations-resources .tab-content .tab-description h3 {
        color: #fff;
        font-size: 25px;
        margin-bottom: 30px;
        font-weight: 700;
    }

    .hire-innovations-resources .nav-link {
        font-size: 17px;
    }

    .hire-innovations-resources .tab-content .tab-description {
        text-align: left;
        color: #fff;
        background: rgba(51, 51, 51, .8);
        width: auto;
        padding: 20px 30px 20px;
        margin-bottom: 40px;
    }


    .our-expert-team p br {
        display: none;
    }

}

//  .container-md {
// 	max-width: 1140px;
//   margin: auto;
// }

// New Design
.devices-detail {
    background: #040623;
    height: 100px;
  }

  .devices-detail-content {
    background: #fff;
    padding: 5% 0px 5%;
  }


  .devices-detail-content h1 {
    text-align: left;
    font-size: 45px;
    line-height: 45px;
    margin-bottom: 20px;
    font-family: 'gt_walsheimbold';
  }

  .devices-detail-content p {
    font-size: 18px;
    line-height:30px;
    margin-left: 9%;
    font-weight: 400;
  }

  .device-detail-thumb img{
      margin: 5% auto;
      display: block;
      width: 250px;
  }
  .device-description .benefits {
    margin: 20px auto;
    border-radius: 20px;
    border:solid 1px #AEAEAE !important;
    padding: 0px 00px 10px;
    width: 93%;
  }

  .device-description .benefits head h4 {
    text-align: left;
    color: #333;
    font-family: 'gt_walsheimbold';
    font-size: 25px;
    line-height: 25px;
  }

  .device-description .benefits .head {
    background:#03DAC4 ;
    text-align: left;
    font-size: 25px;
    padding: 15px;
    border-radius: 20px 20px 0px 0px;
  }


  .device-description .benefits .col-4 {
    width: 40%;
  }

  .device-description .benefits .col-6 {
    width: 60%;
    padding-right: 20px;
  }

  .device-description .benefits .col-4 p, .device-description .benefits .col-6 p {
    margin: 0px !important;
    font-size: 16px;
    padding: 10px 0px 0px 20px;
    min-height: 60px;

  }

  .device-description .order-now {
    width: 198px;
    height: 57px;
    line-height: 57px;
    background: #03DAC4;
    border-radius: 29px;
    text-align: center;
    color: #fff;
    display: block;
    margin: 20px auto;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    border: none;
  }

  .device-description .order-now:hover {
    width: 198px;
    height: 57px;
    line-height: 57px;
    background: #0F3828;
    border-radius: 29px;
    text-align: center;
    color: #fff;
    display: block;
    margin: 20px auto;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    border: none;
    transition: 0.6s;
  }

#orderModal {
    z-index: 99999;
    background: rgba(0, 0, 0, .75);
}

#orderModal .modal-dialog {
    max-width: 70%;
    margin: 2.75rem auto;
}

#orderModal .modal-dialog .button-green {
    max-width: 240px !important;
    margin: 10px auto 20px !important;
    background: #0F3828;
    border-radius: 29px;
    text-align: center;
    padding: 10px;
    color: #fff;
    width: 100%;
    display: block;
    border: none;
}

#orderModal h3.modal-title {
    font-size: 1.8rem;
    margin: 10px 0px;
    text-align: center;
}

#orderModal .modal-body {
    padding: 3% 5% 2%;
}

#orderModal .modal-body p {
    color: #333333;
    font-size: 17px;
    text-align: left;
    font-weight: 400;
}

#orderModal .modal-body p.device-name {
    font-size: 20px;
    margin: 20px auto;
}

#orderModal .modal-body .form-label {
    margin-bottom: 0;
    color: #222222;
    font-size: 16px;
}

#orderModal .modal-body .form-control {
    color: #222222;
    background: #fff;
    height: 45px;
    margin-bottom: 5px;
    border: solid 1px #AEAEAE;
}

#orderModal .modal-dialog .button-green:disabled {
    background-color: #CCCCCC;
}


@media only screen and (min-width: 320px) and (max-width: 991.99px) {
  #orderModal .modal-dialog {
    max-width: 90%;
    margin: 2.75rem auto;
  }
}

/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';

@import './css/about-us.scss';
@import './css/blog.scss';
@import './css/contact-us.scss';
@import './css/devices.scss';
@import './css/device-detail.scss';
@import './css/thankyou.scss';
@import './css/hire-resources.scss';
@import './css/home.scss';
@import './css/industries.scss';
@import './css/not-found.scss';
@import './css/privacy-cookie-policy.scss';
@import './css/common-contact-us.scss';
@import './css/footer.scss';
@import './css/header.scss';
@import './css/loader.scss';

body {
  font-family: 'Onest', sans-serif;
  line-height: 1.5rem;
  font-size: 20px;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0px;
  padding: 0px;
}

.container,
.container-fluid,
.container-lg {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  overflow: hidden;
}

.container-xxl {
  max-width: 1440px;
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.container {
  width: 1320px;
}

.container-lg {
  width: 960px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  font-family: gt_walsheimbold, sans-serif;
}

a:hover,
a:focus {
  text-decoration: none;
}

.form-control:focus,
.btn:focus,
button:focus {
  box-shadow: none;
  outline: none;
}

.mw-95 {
  max-width: 95%;
}

.text-danger {
  font-size: 16px;
}

#contact-form select.form-control {
  background: #0B0B26;
  border-radius: 10px;
  height: 60px;
  border: solid 2px #242443;
  margin-bottom: 25px;
  color: #fff;
}

.contact-form ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
}

.contact-form :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}

.contact-form ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1;
}

.contact-form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

.contact-form ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.contact-form ::placeholder {
  /* Most modern browsers support this now. */
  color: #fff;
}

.p-toast {
  position: fixed;
  z-index: 1000;
}

@media only screen and (min-width: 1570px) and (max-width: 1920px) {
  .container-xxl {
    max-width: 1440px;
    width: 100%;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .container-fluid {
    max-width: 100%;
    width: 100%;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1440.99px) {
  .container-xxl {
    max-width: 95%;
    width: 100%;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .container {
    max-width: 95%;
  }


  .navbar-expand-md .navbar-nav .nav-item {
    margin: 0px 10px;
  }

  .navbar-brand a {
    width: 200px !important;
  }

  .fixed-top .navbar-brand a {
    width: 200px !important;
  }

  .menu-bg-inner {
    margin-left: 10px !important;
    width: 780px !important;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-size: 17px;
    line-height: 30px;
    font-family: 'Onest', sans-serif;
    font-weight: 100;
    margin: 0px 30px !important;
  }

  .hire-resources {
    background: #fff;
    border-radius: 30px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    padding: 12px 5px;
    color: #000;
    font-weight: 700;
    width: 185px;
    margin-left: 20px;
  }

  .navbar-brand .mw-90 {
    max-width: 100%;
  }

}


@media only screen and (min-width: 992px) and (max-width: 1024.99px) {
  .container-xxl,
  .container {
    max-width: 95%;
    width: 100%;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .container-lg {
    width: 75%;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    padding: 0px 7%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.99px) {

  .container-xxl,
  .container {
    max-width: 95%;
    width: 100%;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .container-lg {
    width: 75%;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    padding: 0px 7%;
  }

  .navbar.navbar-expand-xl.navbar-light.my-2.fixed-top {
    background: #fff;
    margin-top: 1px !important;
  }

  .navbar-expand-xl {
    background: #020221;
    margin: 0px auto !important;
  }

  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .show>.nav-link {
    color: #03DAC4;
    background: none;

  }




  .menu-bg-inner {
    background: transparent;
    height: unset;
    backdrop-filter: unset;
    padding: 20px 20px 0px;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-size: 17px;
    line-height: 27px;
    font-family: 'Onest', sans-serif;
    font-weight: 400;
    margin: 0px 18px;
  }

  .dropdown-menu {
    background: transparent;
    backdrop-filter: unset;
    border: none;
    font-size: 17px;
    padding: 0;
    margin: 0 0px 0px 20px;
  }



  a.hire-resources {
    background: transparent;
    border-radius: 30px;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 0px;
    color: #fff !important;
    font-weight: 400;
    border: none;
    margin-top: 10px;
    z-index: 9999;
    margin-left: 37px;
    width: 100%;
    margin-bottom: 20px;
  }


  .group-of-companies img {
    width: auto;
    margin: 10px 20px;
  }


  .flex-sm-row {
    flex-direction: unset;
    display: inline-block !important;
    padding-left: 0px !important;
  }

  .navbar-expand-md .navbar-nav .nav-item {
    margin: 0px 10px;
  }

  .hire-resources {
    background: #fff;
    border-radius: 30px;
    font-size: 13px;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 15px 5px;
    color: #000;
    font-weight: 700;
    width: 175px;
    margin-left: 20px;
  }

  .navbar-brand .mw-90 {
    max-width: 95%;
  }

  .menu-bg {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 36px;
    height: 420px;
    padding: 3%;
    margin-top: 3%;
    width: 95%;
  }

  a .hire-resources {
    background: transparent;
    border-radius: 30px;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 0px;
    color: #fff !important;
    font-weight: 400;
    border: none;
    margin-top: 10px;
    z-index: 9999;
    margin-left: 37px;
    width: 100%;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width:560px) and (max-width: 767.99px) {
  body {
    font-family: Onest, sans-serif;
    line-height: 30px;
    font-size: 18px;
    overflow-x: hidden;
  }

  .navbar-expand-xl {
    background: #020221;
    margin: 0px auto !important;
  }

  .navbar.navbar-expand-xl.navbar-light.my-2.fixed-top {
    background: #fff;
    margin-top: 1px !important;
  }

  .container-xxl,
  .container {
    max-width: 95%;
    width: 100%;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .container-lg {
    width: 95%;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    padding: 0px 3%;
  }

  .navbar-brand a {
    width: 150px !important;
    height: 37px !important;
  }

}


@media only screen and (min-width:460px) and (max-width: 559.99px) {
  body {
    font-family: Onest, sans-serif;
    line-height: 30px;
    font-size: 18px;
    overflow-x: hidden;
  }

  .navbar-expand-xl {
    background: #020221;
    margin: 0px auto !important;
  }

  .navbar.navbar-expand-xl.navbar-light.my-2.fixed-top {
    background: #fff;
    margin-top: 1px !important;
  }

  .container-xxl,
  .container {
    max-width: 95%;
    width: 100%;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .container-lg {
    width: 95%;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    padding: 0px 3%;
  }

  .navbar-brand a {
    width: 150px !important;
    height: 37px !important;
  }
}

@media only screen and (min-width:360px) and (max-width: 459.99px) {



  .container-xxl,
  .container {
    max-width: 95%;
    width: 100%;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .container-lg {
    width: 95%;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    padding: 0px 3%;
  }

  .menu-bg {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 36px;
    height: 425px;
    padding: 3%;
  }

  a.hire-resources {
    background: transparent;
    border-radius: 30px;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 15px 30px;
    color: #fff !important;
    font-weight: 700;
    border: none;
    margin-top: -5px;
    z-index: 9999;
    margin-left: 2.0%;
    width: 100%;
  }

  .menu-bg-inner {
    background: transparent;
    height: unset;
    backdrop-filter: unset;
    padding: 20px 20px 0px;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-size: 17px;
    line-height: 27px;
    font-family: 'Onest', sans-serif;
    font-weight: 400;
    margin: 0px 18px;
  }

  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .show>.nav-link {
    color: #03DAC4;
    background: none;
  }



  .dropdown-menu {
    background: transparent;
    backdrop-filter: unset;
    border: none;
    font-size: 17px;
    padding: 0;
    margin: 0 0px 0px 20px;
  }


  .carousel-inner {
    padding-top: 50px;
    background: #050521;
  }


  .navbar-toggler-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    content: "";
  }

  .navbar-expand-md .navbar-nav .nav-item {
    margin: 0px 10px;
  }


  .navbar-brand .mw-90 {
    max-width: 70%;
  }

  .navbar-brand {
    width: 76%;
  }

  .navbar-brand a {
    width: 150px !important;
    height: 37px !important;
  }


}


@media only screen and (min-width:320px) and (max-width: 359.99px) {


  .container-xxl,
  .container {
    max-width: 95%;
    width: 100%;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .container-lg {
    width: 95%;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    padding: 0px 3%;
  }

  #carouselExampleCaptions {
    margin-top: 0px;
  }

  .menu-bg {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 36px;
    height: 425px;
    padding: 3%;
  }

  a.hire-resources {
    background: transparent;
    border-radius: 30px;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 15px 30px;
    color: #fff !important;
    font-weight: 700;
    border: none;
    margin-top: -5px;
    z-index: 9999;
    margin-left: 2.0%;
    width: 100%;
  }

  .menu-bg-inner {
    background: transparent;
    height: unset;
    backdrop-filter: unset;
    padding: 20px 20px 0px;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-size: 17px;
    line-height: 27px;
    font-family: 'Onest', sans-serif;
    font-weight: 400;
    margin: 0px 18px;
  }

  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .show>.nav-link {
    color: #03DAC4;
    background: none;

  }

  .dropdown-menu {
    background: transparent;
    backdrop-filter: unset;
    border: none;
    font-size: 17px;
    padding: 0;
    margin: 0 0px 0px 20px;
  }


  .navbar-toggler-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    content: "";
  }


  .navbar-expand-md .navbar-nav .nav-item {
    margin: 0px 10px;
  }

  .hire-resources {
    background: #fff;
    border-radius: 30px;
    font-size: 13px;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 15px 5px;
    color: #000;
    font-weight: 700;
    width: 175px;
    margin-left: 20px;
  }

  .navbar-brand {
    width: 55%;
  }

  .navbar-brand .mw-90 {
    max-width: 75%;
  }

  .navbar-brand a {
    width: 150px !important;
    height: 37px !important;
  }
}
// ========= New Design CSS =========
.categoryTab .nav-tabs {
  background: #FDFDFD;
  border: solid 1px #EEEFF2;
  border-radius: 46px;
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.1);
  line-height: 40px;
  margin: 3em 0 2em;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.categoryTab .nav-tabs .nav-link {
  color: #333333;
  border: unset;
  font-size: 1.1em;
}

.categoryTab .nav-tabs .nav-link.active {
  color: #EF6000;
  border-bottom: 3px solid #EF6000;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  background-color: transparent;
}

.tabBody {
  border-radius: 40px;
  padding: 8%;
  margin-bottom: 30px;
  background: #F3F7FA;
}

.categoryTab .tabBody h2 {
  font-size: 38px;
  text-align: left;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: 'gt_walsheimbold';
  color: #212529;
}

.tabBody h6 {
  color: #EF6000;
  font-weight: 400;
}

.tabBody p {
  color: #333333;
  font-weight: 400;
}

.categoryTab .tabBody h6 {
color: #005193;
font-weight: 400;
font-family: 'Onest', sans-serif;
font-size: 18px;
line-height: 28px;
}
.tabBody p {
  margin-bottom: 10px;
  text-align: left;
  font-size: 18px;
  line-height: 28px;
}

.tabBody ul {
  padding: 0px;
  margin: 30px 0px 0px 5px;
}

.tabBody ul li {
  margin: 0px 0px 10px 0px;
  padding: 0px 0px 0px 25px;
  text-align: left;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  font-weight: 400;
  list-style: none;
}

// New Tab Design
.mobile-application-tab .tabs-container nav {
  margin: 0 auto;
  background: #fff;
}

.mobile-application-tab .tabs-container nav ul {
  list-style: none;
  margin: 0;
  border: 1px solid #ccc;
  padding: 0 10px;
}

.mobile-application-tab .tabs-container nav ul li {
  cursor: pointer;
  color: #333;
  border-top: 0px solid #eee;
  margin: 0px auto;
  padding: 0;
  font-family: 'Onest', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 55px;
}

.mobile-application-tab .tabs-container nav ul li:hover {
  color: #fff;
  background: #03DAC4;
  border-radius: 45px;
}

.mobile-application-tab .tabs-container nav ul li.active {

  background: #03DAC4;
  border-radius: 45px;
  margin: 0;
  border: none;
  font-size: 17px;
  font-weight: 500;
  color: #fff;
}



.mobile-application-tab .tabs-container {
  width: 100%;
  margin: 0 auto 30px;
}

.mobile-application-tab .tabs-container .tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.tabs ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mobile-application-tab .tabs-container nav {
  position: absolute;
  width: 100%;
}

.mobile-application-tab .tabs-container nav ul {
  display: flex;
  flex-direction: column;
}

.mobile-application-tab .tabs-container nav ul li {
  order: 1;
}


.mobile-application-tab .tabs-container .tabs .btn  {
  background: transparent;
  color: #03dac4;
  width: 2.357rem;
  box-shadow: unset;
  border-radius: 0 45px 45px 0;
  right: 0;
  height: 65px;
  font-size: 20px;
  padding: 5px 10px;
}

.mobile-application-tab .tabs-container .tabs .tab-content {
  display: none;
}

.mobile-application-tab .tabs-container .tabs .tab-content.current {
  display: block;
}

.mobile-application-tab .tabs-container nav ul li.active {
  order: 0;
  background: #03DAC4;
  color: #fff;
  line-height: 55px;
  text-align: center;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
}

.what-we-do .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  display: inline-block;
  margin: 0;
  border: none;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background: #03DAC4;
  border-radius: 45px;
  outline: none;
}


.mobile-application-tab .tabs-container nav ul li.active:after {
  width: 1px;
  height: 1px;
  content: '';
  position: absolute;
  right: 1.5em;
  top: 1.4em;
  z-index: 9999;
}

.mobile-application-tab .tabs-container nav ul li:not(.active) {
  position: absolute;
  top: -999em;
}

.mobile-application-tab .tabs-container nav ul.expanded li.active:after {
  border-top-color: transparent;
  border-bottom-color: #555;
  top: 1em;
}

.mobile-application-tab .tabs-container nav ul.expanded li:not(.active) {
  position: relative;
  top: auto;
}
@media all and (min-width: 42em) {

  .mobile-application-tab nav {
    background: transparent;
  }


  .what-we-do .mobile-application-tab .tab-content.current {
    display: inherit;
    background: transparent;
    border-radius: 40px;
    padding: 0%;
    margin-bottom:0px;
  }

  .diverse-solutions .mobile-application-tab .tab-content.current {
    display: inherit;
    background: transparent;
    border-radius: 40px;
    padding: 0%;
    margin-bottom:0px;
  }

  .diverse-solutions .mobile-application-tab .tab-content p {
    margin-bottom: 10px;
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    color: #333333;
    font-weight: 400;
  }

  .mobile-application-tab .tabs-container nav {
    background: #FDFDFD;
    border: solid 1px #EEEFF2;
    border-radius: 46px;
    -webkit-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.1);
  }
  .mobile-application-tab .tabs-container nav ul {
    flex-direction: row;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
     border: solid 0px #EEEFF2;
    line-height: 65px;
    width: 99%;
    margin: auto;
    text-align: center;
    display: block;
  }

  .mobile-application-tab .tabs-container nav ul li {
    display: inline-block;
    margin: 0 5px;
    border: none;
    background: #E3ECF3;
    border-radius: 45px;
    color: #333;
    font-weight: 400;
    padding: 0px 25px;
    text-align: center;
  }




  .mobile-application-tab .tabs-container nav ul li:not(.active) {
    position: relative;
    top: auto;
  }

  .mobile-application-tab .tabs-container nav ul li.active {
    order: 1;
  }

  .mobile-application-tab .tabs-container nav ul li.active:after {
    display: none;
  }
}

.mobile-application-tab .tab-content {
  display: none;
  padding: 8%;
}

.mobile-application-tab .tab-content.current {
  display: inherit;
  border-radius: 40px;
  padding: 8%;
  margin-bottom: 30px;
}

.mobile-application-tab .tab-content.current ul li {
  margin: 0px 0px 10px 0px;
  padding: 0px 0px 0px 25px;
  text-align: left;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  font-weight: 400;
  list-style: none;
}

.mobile-application-tab .tab-content h2 {
  font-size: 38px;
  text-align: left;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: 'gt_walsheimbold';
}

.mobile-application-tab .tab-content p {
  margin-bottom: 10px;
  text-align: left;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.mobile-application-tab .tab-content p strong {
  color: #005193;
  font-weight: 400;
}

.mobile-application-tab .tab-content.current ul {
  padding: 0px;
  margin: 30px 0px 0px 5px;
}

@media only screen and (min-width:560px) and (max-width: 767.99px) {

  .mobile-application-tab .tabs-container .tabs {
    align-items: center;
    justify-content: space-between;
    position: relative;
    display: block;
  }

  .mobile-application-tab .tab-content h2 {
    font-size: 30px;
    text-align: left;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: gt_walsheimbold;
  }

  .mobile-application-tab .tab-content p {
    margin-bottom: 10px;
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    color: #333;
    font-weight: 400;
  }


  .mobile-application-tab .tabs-container nav ul {
    list-style: none;
    margin: 0;
    background: #ffffff;
    border: 0px solid #dee2e6;
    border-width: 0 0 0px 0;
    padding: 0 10px;
    width: 83%;
    float: left;
  }




  .mobile-application-tab .tab-content.current ul li {
    margin: 0 0 10px;
    padding: 0 0 0 20px;
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    color: #333;
    font-weight: 400;
    list-style: none;
  }

  .mobile-application-tab .tabs-container nav ul li.active {
    order: 0;
    background: #03DAC4;
    color: #fff;
    line-height: 55px;
    text-align: center;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    font-size: 22px;
  }
}

@media only screen and (min-width:460px) and (max-width: 559.99px) {
  .mobile-application-tab .tabs-container .tabs {
    align-items: center;
    justify-content: space-between;
    position: relative;
    display: block;
  }


  .mobile-application-tab .tab-content h2 {
    font-size: 30px;
    text-align: left;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: gt_walsheimbold;
  }

  .mobile-application-tab .tab-content p {
    margin-bottom: 10px;
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    color: #333;
    font-weight: 400;
  }

  // .mobile-application-tab .tab-content.current ul li {
  //   margin: 0 0 10px;
  //   padding: 0 0 0 20px;
  //   text-align: left;
  //   font-size: 16px;
  //   line-height: 26px;
  //   color: #333;
  //   font-weight: 400;
  //   list-style: none;
  // }

  .mobile-application-tab .tab-content h2 {
    font-size: 25px;
    text-align: left;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: gt_walsheimbold;
  }

  .mobile-application-tab .tab-content p {
    margin-bottom: 10px;
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    color: #333;
    font-weight: 400;
  }

  // .mobile-application-tab .tab-content.current ul li {
  //   margin: 0 0 10px;
  //   padding: 0 0 0 20px;
  //   text-align: left;
  //   font-size: 16px;
  //   line-height: 26px;
  //   color: #333;
  //   font-weight: 400;
  //   list-style: none;
  // }


  .mobile-application-tab .tabs-container nav ul {
    list-style: none;
    margin: 0;
    border: 0px solid #ccc;
    padding: 0 10px;
    width: 80%;
    float: left;
  }

  .mobile-application-tab .tab-content.current ul li {
    margin: 0 0 10px;
    padding: 0 0 0 20px;
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    color: #333;
    font-weight: 400;
    list-style: none;
  }

  .mobile-application-tab .tabs-container nav ul li.active {
    order: 0;
    background: #03DAC4;
    color: #fff;
    line-height: 55px;
    text-align: center;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    font-size: 22px;
  }
}

@media only screen and (min-width:360px) and (max-width: 459.99px) {
  .mobile-application-tab .tabs-container .tabs {
    align-items: center;
    justify-content: space-between;
    position: relative;
    display: block;
  }

  .mobile-application-tab .tabs-container nav ul li.active::after {
    width: 1px;
    height: 1px;
    border: 5px solid transparent;
    border-top: 5px solid #555;
    content: '';
    position: absolute;
    right: 1.5em;
    top: 1.8rem;
    z-index: 9999;
  }


  .mobile-application-tab .tab-content h2 {
    font-size: 30px;
    text-align: left;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: gt_walsheimbold;
  }

  .mobile-application-tab .tab-content p {
    margin-bottom: 10px;
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    color: #fff;
    font-weight: 400;
  }

  // .mobile-application-tab .tab-content.current ul li {
  //   margin: 0 0 10px;
  //   padding: 0 0 0 20px;
  //   text-align: left;
  //   font-size: 16px;
  //   line-height: 26px;
  //   color: #333;
  //   font-weight: 400;
  //   list-style: none;
  // }

  // .mobile-application-tab .tabs-container .tabs {
  //   align-items: center;
  //   justify-content: space-between;
  //   position: relative;
  //   display: block;
  // }

  // .mobile-application-tab .tabs-container nav ul li.active::after {
  //   width: 1px;
  //   height: 1px;
  //   border: 5px solid transparent;
  //   border-top: 5px solid #555;
  //   content: '';
  //   position: absolute;
  //   right: 1.5em;
  //   top: 1.8rem;
  //   z-index: 9999;
  //   display: none;
  // }

  // .mobile-application-tab .tabs-container nav ul li.active {
  //   order: 0;
  //   background: transparent;
  //   color: #EF6000;
  //   line-height: 20px;
  //   text-align: center;
  //   border-left: 0px;
  //   border-right: 0px;
  //   border-top: 0px;
  //   box-shadow:none;
  //   font-size: 16px;
  // }

  .mobile-application-tab .tabs-container .tabs .btn img {
    width: 20px;
  }
  .mobile-application-tab .tabs-container .tabs .btn {
    font-size: 18px;
    padding: 13px 10px;
    background: transparent;
  }



  .mobile-application-tab .tabs-container nav ul {
    list-style: none;
    margin: 0;
    border: 0px solid #ccc;
    padding: 0 10px;
    width: 75%;
    float: left;
  }

  .mobile-application-tab .tab-content.current ul li {
    margin: 0 0 10px;
    padding: 0 0 0 20px;
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    color: #333;
    font-weight: 400;
    list-style: none;
  }

  .mobile-application-tab .tabs-container nav ul li.active {
    order: 0;
    background: #03DAC4;
    color: #fff;
    line-height: 55px;
    text-align: center;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    font-size: 22px;
  }

  .mobile-application-tab .tab-content.current {
    display: inherit;
    border-radius: 40px;
    padding: 4%;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width:320px) and (max-width: 359.99px) {
  .mobile-application-tab .tabs-container .tabs {
    align-items: center;
    justify-content: space-between;
    position: relative;
    display: block;
  }

  .mobile-application-tab .tabs-container nav ul li {
    cursor: pointer;
    color: #333;
    border-top: 0px solid #eee;
    margin: 0px auto;
    padding: 0;
    font-family: 'Onest', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 60px;
  }

  .mobile-application-tab .tabs-container nav ul li.active::after {
    width: 1px;
    height: 1px;
    border: 5px solid transparent;
    border-top: 5px solid #555;
    content: '';
    position: absolute;
    right: 1.5em;
    top: 1.8rem;
    z-index: 9999;
    display: none;
  }
  .mobile-application-tab .tabs-container .tabs .btn img {
    width: 20px;
  }
  .mobile-application-tab .tabs-container .tabs .btn {
    font-size: 20px;
    padding: 13px 10px;
    background: transparent;
  }

  .mobile-application-tab .tabs-container .tabs {
    align-items: center;
    justify-content: space-between;
    position: relative;
    display: block;
  }

  .mobile-application-tab .tabs-container nav ul li {
    cursor: pointer;
    color: #333;
    border-top: 0px solid #eee;
    margin: 0px auto;
    padding: 0;
    font-family: 'Onest', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 60px;
  }

  .mobile-application-tab .tabs-container nav ul li.active::after {
    width: 1px;
    height: 1px;
    border: 5px solid transparent;
    border-top: 5px solid #555;
    content: '';
    position: absolute;
    right: 1.5em;
    top: 1.8rem;
    z-index: 9999;
  }

  .mobile-application-tab .tabs-container .tabs {
    align-items: center;
    justify-content: space-between;
    position: relative;
    display: block;
  }

  .mobile-application-tab .tabs-container nav ul li {
    cursor: pointer;
    color: #333;
    border-top: 0px solid #eee;
    margin: 0px auto;
    padding: 0;
    font-family: 'Onest', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 60px;
  }

  .mobile-application-tab .tabs-container nav ul li.active::after {
    width: 1px;
    height: 1px;
    border: 5px solid transparent;
    border-top: 5px solid #555;
    content: '';
    position: absolute;
    right: 1.5em;
    top: 1.8rem;
    z-index: 9999;
  }


  .mobile-application-tab .tab-content h2 {
    font-size:25px;
    text-align: left;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: gt_walsheimbold;
  }

  .mobile-application-tab .tab-content p {
    margin-bottom: 10px;
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    color: #333;
    font-weight: 400;
  }

  .mobile-application-tab .tab-content.current ul li {
    margin: 0 0 10px;
    padding: 0 0 0 20px;
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    color: #333;
    font-weight: 400;
    list-style: none;
  }



  .mobile-application-tab .tabs-container nav ul {
    list-style: none;
    margin: 0;
    border: 0px solid #ccc;
    padding: 0 10px;
    width: 72%;
    float: left;
  }

  .mobile-application-tab .tab-content.current ul li {
    margin: 0 0 10px;
    padding: 0 0 0 20px;
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    color: #333;
    font-weight: 400;
    list-style: none;
  }

  .mobile-application-tab .tabs-container nav ul li.active {
    order: 0;
    background: #03DAC4;
    color: #fff;
    line-height: 55px;
    text-align: center;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    font-size: 22px;
  }

  .mobile-application-tab .tab-content.current {
    display: inherit;
    border-radius: 40px;
    padding: 4%;
    margin-bottom: 30px;
  }
}

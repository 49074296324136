.industries-banner {
    background: url(../assets/img/industries/banner-industries.png) no-repeat center top;
    max-height: 800px;
    padding: 25% 0px 25%;
}

.banner-industries-content {
    text-align: left;
    color: #fff;
    padding: 0px 0px;
    width: 50%;
    float: left;
    margin-left: 0%;
    margin-top: 10%;
}

.banner-industries-content h1 {
    font-size: 45px;
    line-height: 45px;
    margin-bottom: 20px;
}

.banner-industries-content p {
    font-size: 24px;
    line-height: 34px;
}

.revolution-industries {
    padding: 0% 0px 0%;
}

.revolution-industries-content {
    padding: 9% 0px 0%;
}

.revolution-industries-content h2 {
    text-align: left;
    font-size: 33px;
    margin-bottom: 30px;
}

.revolution-industries-content h2 span {
    color: #03DAC4;
}

.revolution-industries-content p {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 30px;
    font-weight: 400;
}

.revolution-bg {
    background: url(../assets/img/industries/bg-future-content.png) no-repeat left bottom;
    min-height: 450px;
    background-size: contain;
    position: relative;
}

.revolution-bg h4 {
    font-size: 22px;
    position: absolute;
    left: 33px;
    bottom: 45px;
    font-family: 'Onest', sans-serif;
    font-weight: 700;
}

.revolution-bg h5 {
    font-size: 18px;
    position: absolute;
    left: 33px;
    bottom: 115px;
    font-family: 'Onest', sans-serif;
    font-weight: 700;
}

.diverse-solutions {
    background: #020221;
    margin: 0px auto;
    padding: 5% 0px 2%;
    max-height: 900px;
}

.diverse-solutions .mobile-application-tab .tabs-container .tabs .btn {
display: none;
}

.diverse-solutions .mobile-application-tab .tabs-container nav ul li {
	display: inline-block;
	margin: 0 5px;
	border: none;
	background: #E3ECF3;
	border-radius: 45px;
	color: #333;
	font-weight: 400;
	padding: 0 25px;
	text-align: center;
  min-width: 210px;
}

.diverse-solutions h2 {
    color: #fff;
    font-size: 33px;
    line-height: 43px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: 700;
    padding: 20px 0px;
}

.diverse-solutions .tabs-container {
    position: relative;
    padding: 3% 0px 0%;
    margin: auto;
}

.diverse-solutions .tabs-container nav {
    max-width: 1320px;
}

.diverse-solutions nav.tabs {
    margin: 0 auto;
}

.diverse-solutions .nav-link {
    color: #fff;
    font-size: 17px;
    display: inline-block;
    margin: 0 1rem;
    padding: 0 0 .2rem;
    border: none;
}

.diverse-solutions .nav-link.active {
    color: #03dac4;
    border-bottom: solid 3px #03DAC4;
    background: transparent;
}

.diverse-solutions .p-component, .diverse-solutions .p-component * {
  box-sizing: border-box;

}

.diverse-solutions .tab-content .tab-description {
    text-align: left;
    color: #333333;
    position: relative;
    bottom: 32rem;
    left: 65px;
    width: 90%;
}

.diverse-solutions .tab-content .tab-description h3 {
    color: #333333;
    font-size: 28px;
    margin-bottom: 10px;
}

.diverse-solutions .tab-content .tab-description p {
    font-size: 17px;
    color: #333333;
}

.diverse-solutions .tab-content .tab-description p img {
    width: 200px;
}

.diverse-solutions .tab-content .tab-description a.request {
    background: #E3ECF3;
    width: 305px;
    height: 57px;
    color: #333333;
    border-radius: 29px;
    text-align: center;
    line-height: 57px;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 25px 0px;
    border: solid 1px #0F3828;
    display: block;
    text-decoration: none;
}

.diverse-solutions .tab-content .tab-description a:hover.request {
    background: #0F3828;
    color: #fff;
    transition: 0.6s;
}

.diverse-solutions .tab-content .more-info img.left {
    position: absolute;
    right: 130px;
    bottom: 55px;
    transition: 0.6s;
}

.diverse-solutions .tab-content .more-info img.right {
    position: absolute;
    right: 50px;
    bottom: 55px;
}

@media only screen and (min-width: 1600px) and (max-width: 1920px) {
.industries-banner {
	max-height: 800px;
	padding: 25% 0 15%;
}
}

@media only screen and (min-width: 1280px) and (max-width: 1440.99px) {
    .diverse-solutions {
        background: #020221;
        margin: 0px auto;
        padding: 5% 0px 2%;
        max-height: 900px;
    }

    .diverse-solutions .tabs-container {
        position: relative;
        padding: 3% 0 0%;
        margin: auto;

        width: 95%;
    }

    .diverse-solutions .tab-content .tab-description {
      text-align: left;
      color: #333;
      position: relative;
      bottom: 28rem;
      left: 65px;
      width: 90%;
    }

.tab-content {
	height: 510px;
	background: #fff;
	border-radius: 20px;
}

.tab-content .mx-auto img {
	position: relative;
	bottom: 0px;
}

.diverse-solutions .tab-content .tab-description p img {
	width: 150px;
}
}


@media only screen and (min-width: 992px) and (max-width: 1024.99px) {
    .diverse-solutions {
        background: #020221;
        margin: 0px auto;
        padding: 5% 0px 2%;
        max-height: 900px;
    }

    .diverse-solutions .tabs-container {
        position: relative;
        padding: 3% 0 0%;
        margin: auto;
        width: 95%;
    }

    .diverse-solutions #myTabContent img.mw-100 {
        height: 560px;
    }

    .diverse-solutions .tab-content .tab-description {
      text-align: left;
      color: #fff;
      position: relative;
      bottom: 20rem;
      left: 40px;
      width: 90%;
    }


    .diverse-solutions .tabs-container nav ul {
        margin-bottom: 20px;
    }

    .diverse-solutions .tabs-container nav ul li {
        display: inline-block;
        margin: 0 1.5rem;
        padding: 0 0 .2rem;
        border: none;
        font-size: 17px;
        font-weight: 500;
    }

    .banner-industries-content h1 {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 20px;
    }

    .banner-industries-content p {
        font-size: 22px;
        line-height: 32px;
    }

    .revolution-industries-content {
        padding: 5% 0 0%;
    }

.diverse-solutions .tab-content {
	height: 560px;
	background: #fff;
	border-radius: 20px;
}

.diverse-solutions .tab-content .mx-auto img {
	position: relative;
	bottom: -160px;
}

.diverse-solutions .tab-content .tab-description p img {
	width: 150px;
}
}

@media only screen and (min-width: 768px) and (max-width: 991.99px) {
    .diverse-solutions {
        background: #020221;
        margin: 0px auto;
        padding: 5% 0px 2%;
        max-height: 900px;
    }

    .diverse-solutions .tabs-container {
        position: relative;
        padding: 3% 0 0%;
        margin: auto;

        width: 95%;
    }

    .diverse-solutions #myTabContent img.mw-100 {
        height: 650px;
        border-radius: 29px;
    }

    .diverse-solutions .tab-content .tab-description {
        text-align: left;
        color: #fff;
        position: relative;
        bottom: 15rem;
        left: 30px;
        width: 90%;
    }

    .diverse-solutions .tabs-container nav ul {
        margin-bottom: 20px;
    }

    .diverse-solutions .tabs-container nav ul li {
        display: inline-block;
        margin: 0 0.5rem;
        padding: 0 0 .2rem;
        border: none;
        font-size: 17px;
        font-weight: 500;
    }

    .banner-industries-content h1 {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 20px;
    }

    .banner-industries-content p {
        font-size: 22px;
        line-height: 32px;
    }

    .revolution-industries-content {
        padding: 5% 0 0%;
    }

    .industries-banner {
        height: 500px;
        padding: 25% 0;
    }

    .revolution-industries-content h2 {
        text-align: left;
        font-size: 30px;
        margin-bottom: 20px;
    }

    .revolution-industries-content h2 br {
        display: none;
    }

    .revolution-industries-content p {
        font-size: 18px;
        margin-bottom: 10px;
        line-height: 28px;
        font-weight: 400;
    }

    .revolution-bg {
        background-color: #F8F8F8;
        background-position-y: top;
        min-height: 450px;
        background-size: contain;
        position: relative;
    }


    .revolution-bg h5 {
        font-size: 18px;
        position: absolute;
        left: 33px;
        bottom: 135px;
        font-family: 'Onest', sans-serif;
        font-weight: 700;
    }
.tab-content {
	height: 600px;
	background: #fff;
	border-radius: 20px;
}

.tab-content .mx-auto img {
	position: relative;
	bottom: -295px;
}

.diverse-solutions .tab-content .tab-description p img {
	width: 150px;
}
}

@media only screen and (min-width: 560px) and (max-width: 767.99px) {
    .diverse-solutions {
        background: #020221;
        margin: 0px auto;
        padding: 5% 0px 2%;
        max-height: 1000px;
    }

    .diverse-solutions .tabs-container {
        position: relative;
        padding: 3% 0 0%;
        margin: auto;

        width: 95%;
    }

    .diverse-solutions #myTabContent img.mw-100 {
        height: 650px;
        border-radius: 29px;
    }

    .diverse-solutions .tab-content .tab-description {
        text-align: left;
        color: #fff;
        position: relative;
        bottom: 10rem;
        left: 20px;
        width: 90%;
    }


    .diverse-solutions .tabs-container nav ul {
        margin-bottom: 20px;
    }

    .diverse-solutions .tabs-container nav ul li {
        display: inline-block;
        margin: 0 0.5rem;
        padding: 0 0 .2rem;
        border: none;
        font-size: 17px;
        font-weight: 500;
    }

    .banner-industries-content h1 {
        font-size: 38px;
        line-height: 40px;
        margin-bottom: 20px;
    }

    .banner-industries-content p {
        font-size: 20px;
        line-height: 32px;
    }

    .revolution-industries-content {
        padding: 5% 0 0%;
    }

    .industries-banner {
        height: 500px;
        padding: 25% 0;
    }

    .revolution-industries-content h2 {
        text-align: left;
        font-size: 30px;
        margin-bottom: 20px;
    }

    .revolution-industries-content h2 br {
        display: none;
    }

    .revolution-industries-content p {
        font-size: 18px;
        margin-bottom: 10px;
        line-height: 28px;
        font-weight: 400;
    }

    .revolution-bg {
        background-position-y: top;
        background-position-x: center;
        min-height: 450px;
        background-size: contain;
        position: relative;
        margin: auto;
        max-width: 320px;
    }


    .revolution-bg h5 {
        font-size: 18px;
        position: absolute;
        left: 33px;
        bottom: 135px;
        font-family: 'Onest', sans-serif;
        font-weight: 700;
    }

    .diverse-solutions .mobile-application-tab .tab-content {
	height: 700px;
	background: #fff;
	border-radius: 20px;
  margin-top: 80px;
}

.diverse-solutions .tab-content .mx-auto img {
	position: relative;
	bottom: -480px;
}

.diverse-solutions .tab-content .tab-description p img {
	width: 110px;
}

.diverse-solutions .mobile-application-tab .tabs-container .tabs .btn {
	display: block;
}

.diverse-solutions .mobile-application-tab .tabs-container nav ul {
	list-style: none;
	margin: 0;
	background: transparent;
	border: 0px solid #dee2e6;
	border-width: 0 0 0px 0;
	padding: 0 10px;
	width: 84%;
	float: left;
}

}

@media only screen and (min-width: 460px) and (max-width: 559.99px) {
    .diverse-solutions {
        background: #020221;
        margin: 0px auto;
        padding: 5% 0px 2%;
        max-height: 1000px;
    }

    .diverse-solutions .tabs-container {
        position: relative;
        padding: 3% 0 0%;
        margin: auto;
        width: 95%;
    }

    .diverse-solutions #myTabContent img.mw-100 {
        height: 650px;
        border-radius: 29px;
    }

    .diverse-solutions .tab-content .tab-description {
        text-align: left;
        position: relative;
        bottom: 7rem;
        left: 10px;
        width: 95%;
    }

    .diverse-solutions .tab-content .tab-description p img {
        width: 120px;
    }

    .diverse-solutions .tabs-container nav ul {
        margin-bottom: 20px;
    }

    .diverse-solutions .tabs-container nav ul li {
        display: inline-block;
        margin: 0 0.5rem;
        padding: 0 0 .2rem;
        border: none;
        font-size: 17px;
        font-weight: 500;
    }

    .banner-industries-content h1 {
        font-size: 34px;
        line-height: 36px;
        margin-bottom: 20px;
    }

    .banner-industries-content p {
        font-size: 18px;
        line-height: 30px;
    }

    .revolution-industries-content {
        padding: 5% 0 0%;
    }

    .industries-banner {
        height: 500px;
        padding: 25% 0;
    }

    .revolution-industries-content h2 {
        text-align: left;
        font-size: 28px;
        margin-bottom: 10px;
    }

    .revolution-industries-content h2 br {
        display: none;
    }

    .revolution-industries-content p {
        font-size: 18px;
        margin-bottom: 10px;
        line-height: 28px;
        font-weight: 400;
    }

    .revolution-bg {
        background-position-y: top;
        background-position-x: center;
        min-height: 450px;
        background-size: contain;
        position: relative;
        margin: auto;
        max-width: 320px;
    }


    .revolution-bg h5 {
        font-size: 18px;
        position: absolute;
        left: 33px;
        bottom: 135px;
        font-family: 'Onest', sans-serif;
        font-weight: 700;
    }

    .diverse-solutions .tab-content .tab-description h3 {
        font-size: 25px;
        margin-bottom: 10px;
    }

    .diverse-solutions .tab-content .tab-description p {
        font-size: 17px;
        line-height: 27px;
    }


.diverse-solutions .mobile-application-tab .tabs-container nav ul {
	list-style: none;
	margin: 0;
	background: transparent;
	border: 0px solid #dee2e6;
	border-width: 0 0 0px 0;
	padding: 0 10px;
	width: 80%;
	float: left;
}
.diverse-solutions .mobile-application-tab .tab-content {
	height: auto;
	background: #fff;
	border-radius: 20px;
  margin-top: 80px;
}

.diverse-solutions .mobile-application-tab .tabs-container .tabs .btn {
	display: block;
}
}

@media only screen and (min-width: 360px) and (max-width: 459.99px) {
    .diverse-solutions {
        background: #020221;
        margin: 0px auto;
        padding: 5% 0px 2%;
        max-height: 1900px;
    }

    .diverse-solutions .tabs-container {
        position: relative;
        padding: 3% 0 0%;
        margin: auto;

        width: 95%;
    }

    .diverse-solutions #myTabContent img.mw-100 {
        height: 750px;
        border-radius: 29px;
    }

    .diverse-solutions .tab-content .tab-description {
        text-align: left;
        color: #fff;
        position: relative;
        bottom: 5rem;
        left: 10px;
        width: 90%;
    }


    .diverse-solutions .tab-content .tab-description p img.mx-5 {
        margin-right: .5rem !important;
        margin-left: .5rem !important;
    }

    .diverse-solutions .tabs-container nav ul {
        margin-bottom: 20px;
    }

    .diverse-solutions .tabs-container nav ul li {
        display: inline-block;
        margin: 0 0.5rem;
        padding: 0 0 .2rem;
        border: none;
        font-size: 17px;
        font-weight: 500;
    }

    .banner-industries-content h1 {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 20px;
    }

    .banner-industries-content p {
        font-size: 16px;
        line-height: 30px;
    }

    .revolution-industries-content {
        padding: 5% 0 0%;
    }

    .industries-banner {
        height: 500px;
        padding: 25% 0;
    }

    .revolution-industries-content h2 {
        text-align: left;
        font-size: 28px;
        margin-bottom: 10px;
    }

    .revolution-industries-content h2 br {
        display: none;
    }

    .revolution-industries-content p {
        font-size: 16px;
        margin-bottom: 10px;
        line-height: 26px;
        font-weight: 400;
    }

    .revolution-bg {
        background-position-y: top;
        background-position-x: center;
        min-height: 450px;
        background-size: contain;
        position: relative;
        margin: auto;
        max-width: 320px;
    }


    .revolution-bg h5 {
        font-size: 18px;
        position: absolute;
        left: 33px;
        bottom: 135px;
        font-family: 'Onest', sans-serif;
        font-weight: 700;
    }

    .diverse-solutions .tab-content .tab-description h3 {

        font-size: 23px;
        margin-bottom: 10px;
    }

    .diverse-solutions .tab-content .tab-description p {
        font-size: 16px;
        line-height: 27px;

    }

    .banner-industries-content {
        text-align: left;
        color: #fff;
        padding: 0;
        width: 80%;
        float: left;
        margin-left: 0%;
        margin-top: 50%;
    }

  .diverse-solutions .tab-content .tab-description p img {
    width: 200px;
    margin: auto;
    display: block;
  }


.diverse-solutions .mobile-application-tab .tabs-container nav ul {
	list-style: none;
	margin: 0;
	background: transparent;
	border: 0px solid #dee2e6;
	border-width: 0 0 0px 0;
	padding: 0 10px;
	width: 80%;
	float: left;
}
.diverse-solutions .mobile-application-tab .tab-content {
	height: auto;
	background: #fff;
	border-radius: 20px;
  margin-top: 80px;
}

.diverse-solutions .mobile-application-tab .tab-content.current {
	display: inherit;
	border-radius: 40px;
	padding: 4%;
	margin-bottom: 30px;
	height: auto;
}

.diverse-solutions .mobile-application-tab .tabs-container nav ul {
	list-style: none;
	margin: 0;
	background: transparent;
	border: 0px solid #dee2e6;
	border-width: 0 0 0px 0;
	padding: 0 10px;
	width: 75%;
	float: left;
}
.diverse-solutions .mobile-application-tab .tab-content {
	height: 700px;
	background: #fff;
	border-radius: 20px;
  margin-top: 80px;
}

.diverse-solutions .mobile-application-tab .tabs-container .tabs .btn {
	display: block;
}

.diverse-solutions .tab-content .tab-description a.request {
	background: #E3ECF3;
	width: auto;
	height: 57px;
	color: #333;
	border-radius: 29px;
	text-align: center;
	line-height: 57px;
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;
	margin: 25px 0;
	border: solid 1px #0F3828;
	display: block;
	text-decoration: none;
}
}

@media only screen and (min-width: 320px) and (max-width: 359.99px) {
    .diverse-solutions {
        background: #020221;
        margin: 0px auto;
        padding: 5% 0px 2%;
        max-height: 16100px;
    }

    .diverse-solutions .tabs-container {
        position: relative;
        padding: 3% 0 0%;
        margin: auto;

        width: 95%;
    }

    .diverse-solutions #myTabContent img.mw-100 {
        height: 750px;
        border-radius: 29px;
    }

    .diverse-solutions .tab-content .tab-description {
        text-align: left;
        position: relative;
        bottom: 4rem;
        left: 10px;
        width: 95%;
    }


    .diverse-solutions .tab-content .tab-description p img.mx-5 {
        margin-right: .2rem !important;
        margin-left: .2rem !important;
    }

    .diverse-solutions .tabs-container nav ul {
        margin-bottom: 20px;
    }

    .diverse-solutions .tabs-container nav ul li {
        display: inline-block;
        margin: 0 0.5rem;
        padding: 0 0 .2rem;
        border: none;
        font-size: 17px;
        font-weight: 500;
    }

    .banner-industries-content h1 {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 20px;
    }

    .banner-industries-content p {
        font-size: 16px;
        line-height: 30px;
    }

    .revolution-industries-content {
        padding: 5% 0 0%;
    }

    .industries-banner {
        height: 500px;
        padding: 25% 0;
    }

    .revolution-industries-content h2 {
        text-align: left;
        font-size: 28px;
        margin-bottom: 10px;
    }

    .revolution-industries-content h2 br {
        display: none;
    }

    .revolution-industries-content p {
        font-size: 16px;
        margin-bottom: 10px;
        line-height: 26px;
        font-weight: 400;
    }

    .revolution-bg {
        background-position-y: top;
        background-position-x: center;
        min-height: 450px;
        background-size: contain;
        position: relative;
        margin: auto;
        max-width: 320px;
    }


    .revolution-bg h5 {
        font-size: 18px;
        position: absolute;
        left: 33px;
        bottom: 135px;
        font-family: 'Onest', sans-serif;
        font-weight: 700;
    }

    .diverse-solutions .tab-content .tab-description h3 {

        font-size: 23px;
        margin-bottom: 10px;
    }

    .diverse-solutions .tab-content .tab-description p {
        font-size: 16px;
        line-height: 27px;

    }

    .banner-industries-content {
        text-align: left;
        color: #fff;
        padding: 0;
        width: 80%;
        float: left;
        margin-left: 0%;
        margin-top: 50%;
    }

    .diverse-solutions .tab-content .tab-description a.request {
        background: #E3ECF3;
        width: 90%;
        height: 57px;
        color: #333;
        border-radius: 29px;
        text-align: center;
        line-height: 57px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 25px auto;
        border: solid 1px #0F3828;
        display: block;
        text-decoration: none;
        transition: 0.6s;
    }

    .diverse-solutions .tab-content .tab-description p img {
      width: 200px;
      margin: auto !important;
      display: block;
    }


.diverse-solutions .mobile-application-tab .tab-content {
	height: auto;
	background: #fff;
	border-radius: 20px;
  margin-top: 80px;
}

.diverse-solutions .mobile-application-tab .tab-content.current {
	display: inherit;
	border-radius: 40px;
	padding: 4%;
	margin-bottom: 30px;
	height: auto;
}

.diverse-solutions .mobile-application-tab .tabs-container nav ul {
	list-style: none;
	margin: 0;
	background: transparent;
	border: 0px solid #dee2e6;
	border-width: 0 0 0px 0;
	padding: 0 10px;
	width: 72%;
	float: left;
}
.diverse-solutions .mobile-application-tab .tab-content {
	height: 700px;
	background: #fff;
	border-radius: 20px;
  margin-top: 80px;
}

.diverse-solutions .mobile-application-tab .tabs-container .tabs .btn {
	display: block;
}

.diverse-solutions .tab-content .tab-description a.request {
	background: #E3ECF3;
	width: auto;
	height: 57px;
	color: #333;
	border-radius: 29px;
	text-align: center;
	line-height: 57px;
	font-size: 15px;
	font-weight: 700;
	text-transform: uppercase;
	margin: 25px 0;
	border: solid 1px #0F3828;
	display: block;
	text-decoration: none;
}

.diverse-solutions .mobile-application-tab .tabs-container nav ul li {
	display: inline-block;
	margin: 0 5px;
	border: none;
	background: #E3ECF3;
	border-radius: 45px;
	color: #333;
	font-weight: 400;
	padding: 0 25px;
	text-align: center;
	min-width: 185px;
}
}

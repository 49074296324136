.get-in-touch {
    background: url(../assets/img/common/get-in-touch-bg.png) no-repeat center center #fff;
    padding: 8% 0px 7%;
    margin: 0px auto;
    color: #333333;
    background-size: 90%;
}

.get-in-touch .col-md-3 img {
    margin-right: 15%;
}

.get-in-touch h3 {
    color: #333333;
    font-size: 33px;
    text-align: left;
    font-weight: 700;
    padding: 50px 20px 20px 0px;
    width: 78%;
    margin: auto;
}

.get-in-touch h3 span {
    color: #6DFAEC;
}

.get-in-touch .contact {
    background: #DEEAF3;
    border-radius: 30px;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 12px 30px;
    color: #0F3828;
    font-weight: 700;
    border: solid 1px #0F3828;
    width: 200px;
    text-transform: uppercase;
    cursor: pointer;
    margin: 20px 0px 0px 90px;
}

.get-in-touch .contact a {
    color: #0F3828;
    text-decoration: none;
}

.get-in-touch .contact:hover {
    background: #03DAC4;
    border-radius: 30px;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 12px 30px;
    color: #fff;
    font-weight: 700;
    border: solid 1px #0F3828;
    width: 200px;
    text-transform: uppercase;
    transition: 0.6s;
}

@media only screen and (min-width: 560px) and (max-width: 767.99px) {
    .get-in-touch h3 {
        color: #333;
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        padding: 0 !important;
        width: 100% !important;
        margin: auto;
    }

    .get-in-touch .col-md-3 img {
        margin: 40px auto;
        display: block;
        width: 200px;
    }
}

@media only screen and (min-width: 460px) and (max-width: 559.99px) {
    .get-in-touch h3 {
        color: #333;
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        padding: 0 !important;
        width: 100% !important;
        margin: auto;
    }

    .get-in-touch .col-md-3 img {
        margin: 40px auto;
        display: block;
        width: 200px;
    }
}

@media only screen and (min-width: 360px) and (max-width: 459.99px) {

    .get-in-touch .contact {
        background: #DEEAF3;
        border-radius: 30px;
        font-size: 15px;
        line-height: 25px;
        text-align: center;
        text-decoration: none;
        padding: 12px 30px;
        color: #0f3828;
        font-weight: 700;
        border: solid 1px #0F3828;
        width: 200px;
        text-transform: uppercase;
        cursor: pointer;
        margin: 20px auto;
    }

    .get-in-touch h3 {
        color: #333;
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        padding: 0 !important;
        width: 100% !important;
        margin: auto;
    }

    .get-in-touch .col-md-3 img {
        margin: 40px auto;
        display: block;
        width: 200px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 359.99px) {
    .get-in-touch h3 {
        color: #333;
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        padding: 0 !important;
        width: 100% !important;
        margin: auto;
    }

    .get-in-touch .col-md-3 img {
        margin: 40px auto;
        display: block;
        width: 200px;
    }

    .get-in-touch .contact {
        background: #DEEAF3;
        border-radius: 30px;
        font-size: 15px;
        line-height: 25px;
        text-align: center;
        text-decoration: none;
        padding: 12px 30px;
        color: #0f3828;
        font-weight: 700;
        border: solid 1px #0F3828;
        width: 200px;
        text-transform: uppercase;
        cursor: pointer;
        margin: 20px auto;
    }
}

.navbar-expand-xl {
    position: fixed;
    margin: auto;
    text-align: center;
    width: 100%;
    z-index: 99;
}
.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .55);
    border-color: rgba(0, 0, 0, .1);
    background: white;
}
.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    position: relative;
    top:-2px
}
.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none;
}
.navbar-toggler-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    content: "";
    // background: no-repeat center center;
    // background-size: 100% 100%;
}
.menu-bg-inner {
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    border-radius: 36px;
    height: 50px;
    margin-left: 115px;
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
    color: #fff;
    border-bottom: 3px solid #03DAC4;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #fff;
    border-bottom: 3px solid #03DAC4;
}
.navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-size: 17px;
    line-height: 30px;
    font-weight: 400;
    margin: 0px 30px;
}
.navbar-brand a {
    background: url(../assets/img/header/logo.svg) no-repeat left top;
    width: 210px;
    height: 57px;
    display: block;
    background-size: contain;
}
a.hire-resources {
    background: #fff;
    border-radius: 30px;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    padding: 15px 25px;
    color: #000 !important;
    font-weight: 700;
    border: none;
    text-transform: uppercase;
}
a.hire-resources:hover {
    color: #fff !important;
    background: #0F3828;
}
.fixed-top {
    background: rgba(255, 255, 255, 0.95);
    margin-top: 0px !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.fixed-top ul.menu-bg-inner {
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(0px);
    border-radius: 0px;
    height: 50px;
}
.fixed-top ul.menu-bg-inner .nav-link,
.fixed-top .navbar-light .navbar-nav .show>.nav-link,
.fixed-top ul.menu-bg-inner .nav-link.active {
    color: #000 !important;
}
.fixed-top ul.menu-bg-inner .nav-link:hover {
    color: #000;
    border-bottom: 3px solid #03DAC4;
    transition: 0.6s;
}
.fixed-top a.hire-resources {
    background: #0F3828;
    color: #fff !important;
}
.fixed-top .navbar-brand a {
    background: url(../assets/img/header/logo-colour.svg) no-repeat left top;
    width: 210px;
    height: 57px;
    display: block;
    background-size: contain;
}
.fixed-top .menu-bg-inner .nav-link {
    line-height: 42px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.99px){

  .navbar-brand a  {
    width: 200px;
    height: 57px;
    display: block;
    background-size: contain;
  }

  .menu-bg-inner {
    margin: 0px auto;
    height: unset;
    backdrop-filter:unset;
    padding: 20px 20px 0px;
    width: 90%;

  }
.navbar.navbar-expand-xl.navbar-light.my-2.fixed-top  {
	background: rgba(255,255,255,.9);
	margin-top: 1px !important;
}
  .navbar-expand-xl {
    background: rgba(0,0,0,.9);
    margin: 0px auto !important;
  }

  .fixed-top .navbar-brand a {
    width: 200px;
    height: 57px;
    display: block;
    background-size: contain;
  }
  .fixed-top ul.menu-bg-inner {
    margin: 0px auto;
    height: unset;
    backdrop-filter:unset;
    padding: 20px 20px 0px;
    width: 90%;
    background: rgba(0,0,0,.9);
    border-radius: 36px;
  }

  .fixed-top ul.menu-bg-inner .nav-link, .fixed-top .navbar-light .navbar-nav .show > .nav-link, .fixed-top ul.menu-bg-inner .nav-link.active {
    color: #fff !important;
  }

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  font-size: 17px;
  line-height: 27px;
  font-family: 'Onest', sans-serif;
  font-weight: 400;
  margin: 0px 18px;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show > .nav-link {
  color: #03DAC4;
  background: none;

}

.dropdown-menu {
  background: transparent;
  backdrop-filter:unset;
  border: none;
  font-size: 17px;
  padding: 0;
  margin: 0 0px 0px 20px;
}

a.hire-resources  {
	background: #fff;
	border-radius: 30px;
	font-size: 15px;
	line-height: 20px;
	text-align: center;
	text-decoration: none;
	padding: 15px 25px;
	color: #000 !important;
	font-weight: 700;
	border: none;
	text-transform: uppercase;
	width: 90%;
	margin: auto;
}


.navbar-expand-md .navbar-nav .nav-item {
  margin: 0px 10px;
}
.hire-resources {
  background: #fff;
  border-radius: 30px;
  font-size: 13px;
  line-height: 25px;
  text-align: center;
  text-decoration: none;
  padding: 15px 5px;
  color: #000;
  font-weight: 700;
  width: 175px;
  margin-left: 20px;
}
.navbar-toggler {
	padding: .25rem .75rem;
	font-size: 1.25rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: .25rem;
	position: relative;
	top: -2px;
	right: 35px;
}

}

@media only screen and (min-width: 768px) and (max-width: 991.99px){

  .navbar-brand a  {
    width: 200px;
    height: 57px;
    display: block;
    background-size: contain;
  }

  .menu-bg-inner {
    margin: 0px auto;
    height: unset;
    backdrop-filter:unset;
    padding: 20px 20px 0px;
    width: 90%;

  }
.navbar.navbar-expand-xl.navbar-light.my-2.fixed-top  {
  background: rgba(255,255,255,.9);
	  margin-top: 1px !important;
}

  .navbar-expand-xl {
    background: rgba(0,0,0,.9);
    margin: 0px auto !important;
  }

  .fixed-top .navbar-brand a {
    width: 200px;
    height: 57px;
    display: block;
    background-size: contain;
  }
  .fixed-top ul.menu-bg-inner {
    margin: 0px auto;
    height: unset;
    backdrop-filter:unset;
    padding: 20px 20px 0px;
    width: 90%;
    background: rgba(0,0,0,.9);
    border-radius: 36px;
  }

  .fixed-top ul.menu-bg-inner .nav-link, .fixed-top .navbar-light .navbar-nav .show > .nav-link, .fixed-top ul.menu-bg-inner .nav-link.active {
    color: #fff !important;
  }

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  font-size: 17px;
  line-height: 27px;
  font-family: 'Onest', sans-serif;
  font-weight: 400;
  margin: 0px 18px;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show > .nav-link {
  color: #03DAC4;
  background: none;

}

.dropdown-menu {
  background: transparent;
  backdrop-filter:unset;
  border: none;
  font-size: 17px;
  padding: 0;
  margin: 0 0px 0px 20px;
}

a.hire-resources  {
	background: #fff;
	border-radius: 30px;
	font-size: 15px;
	line-height: 20px;
	text-align: center;
	text-decoration: none;
	padding: 15px 25px;
	color: #000 !important;
	font-weight: 700;
	border: none;
	text-transform: uppercase;
	width: 90%;
	margin: auto;
}


.navbar-expand-md .navbar-nav .nav-item {
  margin: 0px 10px;
}
.hire-resources {
  background: #fff;
  border-radius: 30px;
  font-size: 13px;
  line-height: 25px;
  text-align: center;
  text-decoration: none;
  padding: 15px 5px;
  color: #000;
  font-weight: 700;
  width: 175px;
  margin-left: 20px;
}
.navbar-toggler {
	padding: .25rem .75rem;
	font-size: 1.25rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: .25rem;
	position: relative;
	top: -2px;
	right: 35px;
}
}

@media only screen and (min-width: 320px) and (max-width: 767.99px){

  .navbar-brand a  {
    width: 200px;
    height: 57px;
    display: block;
    background-size: contain;
  }

  .menu-bg-inner {
    margin: 0px auto;
    height: unset;
    backdrop-filter:unset;
    padding: 20px 20px 0px;
    width: 90%;

  }
.navbar.navbar-expand-xl.navbar-light.my-2.fixed-top  {
  background: rgba(255,255,255,.9);
	  margin-top: 1px !important;
}

  .navbar-expand-xl {
    background: rgba(0,0,0,.9);
    margin: 0px auto !important;
  }

  .fixed-top .navbar-brand a {
    width: 200px;
    height: 57px;
    display: block;
    background-size: contain;
  }
  .fixed-top ul.menu-bg-inner {
    margin: 0px auto;
    height: unset;
    backdrop-filter:unset;
    padding: 20px 20px 0px;
    width: 90%;
    background: rgba(0,0,0,.9);
    border-radius: 36px;
  }

  .fixed-top ul.menu-bg-inner .nav-link, .fixed-top .navbar-light .navbar-nav .show > .nav-link, .fixed-top ul.menu-bg-inner .nav-link.active {
    color: #fff !important;
  }

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  font-size: 17px;
  line-height: 27px;
  font-family: 'Onest', sans-serif;
  font-weight: 400;
  margin: 0px 18px;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show > .nav-link {
  color: #03DAC4;
  background: none;

}

.dropdown-menu {
  background: transparent;
  backdrop-filter:unset;
  border: none;
  font-size: 17px;
  padding: 0;
  margin: 0 0px 0px 20px;
}

a.hire-resources  {
	background: #fff;
	border-radius: 30px;
	font-size: 15px;
	line-height: 20px;
	text-align: center;
	text-decoration: none;
	padding: 15px 25px;
	color: #000 !important;
	font-weight: 700;
	border: none;
	text-transform: uppercase;
	width: 90%;
	margin: auto;
}


.navbar-expand-md .navbar-nav .nav-item {
  margin: 0px 10px;
}
.hire-resources {
  background: #fff;
  border-radius: 30px;
  font-size: 13px;
  line-height: 25px;
  text-align: center;
  text-decoration: none;
  padding: 15px 5px;
  color: #000;
  font-weight: 700;
  width: 175px;
  margin-left: 20px;
}


.navbar-toggler {
	padding: .25rem .75rem;
	font-size: 1.25rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: .25rem;
	position: relative;
	top: -2px;
	right: 35px;
}
}

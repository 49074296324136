#pause {
    display: block;
    background:
        rgba(0, 0, 0, 0.3) no-repeat 0 0;
    width: 100%;
    height: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1100;
    user-select: none;
}

.loader {
    width: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    background-repeat: no-repeat;
    background-position: center;
    margin: -100px 0 0 -100px;
}
.about-banner {
  background: url(../assets/img/about/banner-about.webp) no-repeat center top;
  max-height: 800px;
  padding: 14.5% 0px;
}

.banner-about-content {
  text-align: center;
  color: #fff;
  padding: 50px 0px;
}

.banner-about-content h1 {
  font-size: 45px;
  line-height: 45px;
  margin-bottom: 20px;
}

.banner-about-content p {
  font-size: 24px;
  line-height: 34px;
}

.about-content {
  background: #F9FBFE;
  padding: 5% 0px 5%;
}

.about-content .bg {
  background: url(../assets/img/about/about-content-bg.png) no-repeat -150px 10px;
}

.about-content h2 {
  font-size: 40px;
  margin-bottom: 45px;
  text-align: left;
  color: #333333;
}

.about-content p {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 30px;
}

.about-content ul.sets li {
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 30px;
  list-style: none;
  background: url(../assets/img/about/bullet.svg) no-repeat left 2px;
  padding: 0px 0px 0px 50px;
  color: #333333;
}

.about-content .management-team {
  padding: 5% 0px;
}

.about-content .management-team img {
  width: 200px;
  margin-bottom: 20px;
}

.about-content .management-team .col {
  text-align: center;
}

.about-content .management-team .col p {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 26px;
}

.about-content .management-team .col p strong {
  padding: 0px;
  text-align: center;
}

.ds-background {
  background: url(../assets/img/about/bg-ds.webp) no-repeat center top;
  padding: 5% 0px 1.6%;
  position: relative;
  background-size: cover;
}

.ds-background .ds-image {
  position: absolute;
  top: -52px;
}

.ds-background .ds-image img {
  width: 357px;
}

.ds-background h3 {
  text-align: left;
  font-size: 40px;
  color: #000000;
  margin-bottom: 20px;
}

.ds-background p {
  font-size: 17px;
  line-height: 27px;
  font-weight: 400;
  padding-bottom: 15px;
}

.certification-bg {
  background: url(../assets/img/about/certification-bg.png) repeat-x center bottom #fff;
  padding-bottom: 3%;
}

.certification {
  background: url(../assets/img/about/certification.webp) no-repeat center top;
  padding: 16% 12%;
  color: #fff;
  background-size: cover;
  border-radius: 20px;

}

.certification h3 {
  text-align: left;
  font-size: 33px;
  margin-bottom: 30px;
  color: #03DAC4;
}

.certification h4 {
  text-align: left;
  font-size: 33px;
  margin-bottom: 5px;
}

.certification p {
  font-size: 19px;
  margin-bottom: 40px;
}

.executive-team {
  padding: 50px 0px;
  background: #F4F8FC;
}

.executive-team h3 {
  text-align: center;
  font-size: 40px;
  color: #000000;
  margin-bottom: 30px;
}

.executive-team .team-member {
  margin-bottom: 20px;
}

.executive-team .team-member img {
  vertical-align: bottom;
}

.executive-team .member-content {
  position: relative;
  opacity: 1;
}

.executive-team .member-content:hover,
.executive-team .team-member:hover .member-content {
  opacity: 1;
}

.executive-team .member-content .linked-in {
  position: absolute;
  top: -338px;
  right: 30px;
}

.executive-team .member-content .details {
  position: absolute;
  bottom: 0;
  color: #fff;
  padding: 15px 12px;
  width: 95%;
  background: linear-gradient(to top, rgba(4, 37, 64, 1) 0%, rgba(4, 37, 64, 0) 100%);
  border-radius: 0px 0px 15px 15px;
}

.executive-team .member-content .details p {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.executive-team .member-content .details h4 {
  font-size: 22px;
  text-align: left;
  margin-bottom: 5px;
}

.ready-to-work {
  background: url(../assets/img/about/ready-to-work.webp) no-repeat center top;
  padding: 54% 13% 12.8%;
  background-size: cover;
  border-radius: 20px;
  color: #fff;
}

.ready-to-work h3 {
  text-align: left;
  font-size: 33px;
  margin-bottom: 30px;
  color: #03DAC4;
}

.ready-to-work p {
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 30px;
}

.ready-to-work .check {
  background: #fff;
  border-radius: 30px;
  text-align: center;
  padding: 20px 0px;
  width: 286px;
  height: 60px;
  color: #000;
  font-size: 18px;
}

.ready-to-work .check:hover {
  background: #03DAC4;
  border-radius: 30px;
  text-align: center;
  padding: 20px 0px;
  width: 286px;
  height: 60px;
  color: #fff;
  font-size: 18px;
  transition: 0.6s;
}

.ready-to-work .check:hover a {
  color: #fff;
}

.ready-to-work .check a {
  color: #000;
  text-decoration: none;
}

@media only screen and (min-width: 1600px) and (max-width: 1920px) {
.ds-background {
  padding: 5% 0 1.6%;
  position: relative;
  background-size: cover;
}
.about-content .bg {
	background-position:-50px 10px;
}
}

@media only screen and (min-width: 1280px) and (max-width: 1440.99px) {

  .about-content .bg {
    background-position: -200px 10px;
  }


  .about-content .management-team img {
    width: 187px;
    margin-bottom: 20px;
  }

  .about-content .management-team .col p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 22px;
  }

  .ds-background {
    padding: 5% 0px 0%;
    position: relative;
    background-size: cover;
  }

  .ds-background .ds-image img {
    width: 367px;
  }

  .ready-to-work {
    padding: 60% 13% 12.8%;
  }

}

@media only screen and (min-width: 992px) and (max-width: 1024.99px) {

  .banner-about-content h1 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .banner-about-content p {
    font-size: 20px;
    line-height: 30px;
  }

  .about-content .management-team img {
    width: 187px;
    margin-bottom: 20px;
  }

  .about-content .management-team .col p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 22px;
  }

  .about-content ul.sets li {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 28px;
    list-style: none;
    padding: 0 0 0 50px;
    color: #333;
  }

  .about-content ul.sets li br {
    display: none;
  }

  .management-team .row .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
    margin: auto;
  }

  .ds-background {
    padding: 5% 0px 0%;
    position: relative;
    background-size: cover;
  }

  .ds-background .ds-image img {
    width: 385px;
  }

  .ds-background h3 {
    text-align: left;
    font-size: 35px;
    color: #000000;
    margin-bottom: 20px;
  }

  .executive-team h3 {
    text-align: center;
    font-size: 35px;
    color: #000000;
    margin-bottom: 30px;
  }

  .executive-team .member-content .details h4 {
    font-size: 20px;
    text-align: left;
    margin-bottom: 5px;
  }

  .executive-team .member-content .details {
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 15px 10px;
    width: 100%;
  }

  .executive-team .team-member img.mw-95 {
    width: 100%;
    max-width: 100%;
  }

  .executive-team .member-content .details p br {
    display: none;
  }

  .ready-to-work {
    padding: 65% 13% 12.8%;
  }
}

@media only screen and (min-width:768px) and (max-width: 991.99px) {

  .banner-about-content h1 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .banner-about-content p {
    font-size: 20px;
    line-height: 30px;
  }

  .about-content .management-team img {
    width: 187px;
    margin-bottom: 20px;
  }

  .about-content .management-team .col p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 22px;
  }

  .about-content ul.sets li {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 28px;
    list-style: none;
    padding: 0 0 0 50px;
    color: #333;
  }

  .about-content ul.sets li br {
    display: none;
  }

  .management-team .row .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
    margin: auto;
  }

  .about-content .bg .row .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
    margin: auto;
  }

  .ds-background {

    padding: 5% 0px 0%;
    position: relative;
    background-size: cover;
  }

  .ds-background .ds-image img {
    width: 300px;
  }

  .ds-background h3 {
    font-size: 35px;
  }

  .ds-background .col-md-8,
  .ds-background .col-md-4 {
    flex: 0 0 auto;
    width: 100%;
  }

  .ds-background .ds-image {
    position: unset;
    top: 0;
    margin: auto;
    text-align: center;
  }

  .executive-team .col-md-3 {
    flex: 0 0 auto;
    width: 40%;
    margin: auto;
  }

  .executive-team .member-content .linked-in {
    position: absolute;
    top: -300px;
    right: 25px;
  }

  .executive-team h3 {
    text-align: center;
    font-size: 35px;
    color: #000000;
    margin-bottom: 30px;
  }

  .ready-to-work {
    padding: 66% 13% 12.8%;
  }

  .ready-to-work p br {
    display: none;
  }

  .executive-team .member-content .details h4 {
    font-size: 24px;
    text-align: left;
    margin-bottom: 5px;
  }

  .executive-team .member-content .details {
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 15px 10px;
    width: 100%;
  }

  .executive-team .member-content .details p br {
    display: none;
  }

}

@media only screen and (min-width:560px) and (max-width: 767.99px) {

  .banner-about-content h1 {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .banner-about-content p {
    font-size: 18px;
    line-height: 25px;
    padding: 0px 10px;
  }

  .banner-about-content p br {
    display: none;
  }

  .about-content h2 {
    font-size: 34px;
    margin-bottom: 36px;
    text-align: center;

  }

  .about-content .management-team img {
    width: 150px;
    margin-bottom: 20px;
  }

  .about-content .management-team .col p {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 20px;
  }

  .about-content ul.sets li {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 28px;
    list-style: none;
    padding: 0 0 0 50px;
    color: #333;
  }

  .about-content ul.sets li br {
    display: none;
  }

  .management-team .row .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
    margin: auto;
  }

  .about-content .bg .row .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
    margin: auto;
  }

  .ds-background {

    padding: 5% 0px 0%;
    position: relative;
    background-size: cover;
  }

  .ds-background .ds-image img {
    width: 300px;
  }

  .ds-background h3 {
    font-size: 30px;
  }

  .ds-background .col-md-8,
  .ds-background .col-md-4 {
    flex: 0 0 auto;
    width: 100%;
  }

  .ds-background .ds-image {
    position: unset;
    top: 0;
    margin: auto;
    text-align: center;
  }

  .executive-team .col-md-3 {
    flex: 0 0 auto;
    width: 40%;
    margin: auto;
  }

  .executive-team .member-content .linked-in {
    position: absolute;
    top: -225px;
    right: 20px;
  }

  .executive-team h3 {
    text-align: center;
    font-size: 30px;
    color: #000000;
    margin-bottom: 30px;
  }

  .ready-to-work {
    padding: 66% 13% 12.8%;
  }

  .ready-to-work p br {
    display: none;
  }

  .executive-team .member-content .details h4 {
    font-size: 20px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 5px;
  }

  .executive-team .member-content .details {
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 15px 10px;
    width: 95%;
  }

  .executive-team .member-content .details p br {
    display: none;
  }

  .certification {
    margin-bottom: 20px;
  }

}

@media only screen and (min-width:460px) and (max-width: 559.99px) {

  .banner-about-content h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .banner-about-content p {
    font-size: 16px;
    line-height: 25px;
    padding: 0px 10px;
  }

  .banner-about-content p br {
    display: none;
  }

  .about-content h2 {
    font-size: 30px;
    margin-bottom: 36px;
    text-align: center;

  }

  .about-content .management-team img {
    width: 100%;
    margin-bottom: 20px;
  }

  .about-content .management-team .col p {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 20px;
  }

  .about-content ul.sets li {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 26px;
    list-style: none;
    padding: 0 0 0 50px;
    color: #333;
  }

  .about-content ul.sets li br {
    display: none;
  }

  .management-team .row .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
    margin: auto;
  }

  .about-content .bg .row .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
    margin: auto;
  }

  .ds-background {

    padding: 5% 0px 0%;
    position: relative;
    background-size: cover;
  }

  .ds-background .ds-image img {
    width: 300px;
  }

  .ds-background h3 {
    font-size: 28px;
  }

  .ds-background .col-md-8,
  .ds-background .col-md-4 {
    flex: 0 0 auto;
    width: 100%;
  }

  .ds-background .ds-image {
    position: unset;
    top: 0;
    margin: auto;
    text-align: center;
  }

  .executive-team .col-md-3 {
    flex: 0 0 auto;
    width: 100%;
    margin: auto;
  }

  .executive-team .member-content .linked-in {
    position: absolute;
    top: -355px;
    right: 50px;
  }

  .executive-team h3 {
    text-align: center;
    font-size: 30px;
    color: #000000;
    margin-bottom: 30px;
  }

  .ready-to-work {
    padding: 66% 13% 12.8%;
  }

  .ready-to-work p br {
    display: none;
  }

  .executive-team .member-content .details h4 {
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 0px;
  }

  .executive-team .member-content .details {
    position: absolute;
    bottom: 1px;
    color: #fff;
    padding: 10px 10px 5px;
    width: 89%;
    left: 5%;
  }

  .executive-team .team-member {
    max-width: 350px;
    margin: 20px auto;
    text-align: center;
  }

  .executive-team .team-member img.mw-95 {
    max-width: 100%;
  }

  .executive-team .member-content .details p br {
    display: none;
  }

  .certification {
    margin-bottom: 20px;
  }

}

@media only screen and (min-width:360px) and (max-width: 459.99px) {

  .banner-about-content h1 {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .banner-about-content p {
    font-size: 16px;
    line-height: 25px;
    padding: 0px 10px;
  }

  .banner-about-content p br {
    display: none;
  }

  .about-content h2 {
    font-size: 30px;
    margin-bottom: 36px;
    text-align: center;

  }

  .about-content .management-team img {
    width: 100%;
    margin: 0px auto 20px;
  }

  .about-content .management-team .col p {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 20px;
  }

  .about-content ul.sets li {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 26px;
    list-style: none;
    padding: 0 0 0 50px;
    color: #333;
  }

  .about-content ul.sets li br {
    display: none;
  }

  .management-team .row .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
    margin: auto;
  }

  .about-content .bg .row .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
    margin: auto;
  }

  .ds-background {

    padding: 5% 0px 0%;
    position: relative;
    background-size: cover;
  }

  .ds-background .ds-image img {
    width: 300px;
  }

  .ds-background h3 {
    font-size: 28px;
  }

  .ds-background .col-md-8,
  .ds-background .col-md-4 {
    flex: 0 0 auto;
    width: 100%;
  }

  .ds-background .ds-image {
    position: unset;
    top: 0;
    margin: auto;
    text-align: center;
  }

  .ds-background .col-md-8 p br {
    display: none;
  }

  .executive-team .col-md-3 {
    flex: 0 0 auto;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .executive-team .member-content .linked-in {
    position: absolute;
    top: -350px;
    right: 40px;
  }

  .executive-team h3 {
    text-align: center;
    font-size: 30px;
    color: #000000;
    margin-bottom: 30px;
  }

  .ready-to-work {
    padding: 66% 13% 12.8%;
  }

  .ready-to-work p br {
    display: none;
  }

  .executive-team .member-content .details h4 {
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 0px;
  }

  .executive-team .member-content .details {
    position: absolute;
    bottom: 1px;
    color: #fff;
    padding: 10px 10px 5px;
    width: 90%;
    left: 5%;
  }

  .executive-team .team-member {
    max-width: 340px;
    margin: 20px auto;
    text-align: center;
  }

  .executive-team .team-member img.mw-95 {
    max-width: 90%;
  }

  .executive-team .member-content .details p br {
    display: none;
  }

  .ready-to-work .check {
    background: #fff;
    border-radius: 30px;
    text-align: center;
    padding: 20px 0;
    width: 260px;
    height: 60px;
    color: #000;
    font-size: 16px;
  }

  .certification {
    margin-bottom: 20px;
  }

}

@media only screen and (min-width:320px) and (max-width: 359.99px) {

  .banner-about-content h1 {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .banner-about-content p {
    font-size: 16px;
    line-height: 25px;
    padding: 0px 10px;
  }

  .banner-about-content p br {
    display: none;
  }

  .about-content h2 {
    font-size: 30px;
    margin-bottom: 36px;
    text-align: center;

  }

  .about-content .management-team img {
    width: 100%;
    margin: 0px auto 20px;
  }

  .about-content .management-team .col p {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 20px;
  }

  .about-content ul.sets li {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 26px;
    list-style: none;
    padding: 0 0 0 50px;
    color: #333;
  }

  .about-content ul.sets li br {
    display: none;
  }

  .management-team .row .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
    margin: auto;
  }

  .about-content .bg .row .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
    margin: auto;
  }

  .ds-background {

    padding: 5% 0px 0%;
    position: relative;
    background-size: cover;
  }

  .ds-background .ds-image img {
    width: 300px;
  }

  .ds-background h3 {
    font-size: 28px;
  }

  .ds-background .col-md-8,
  .ds-background .col-md-4 {
    flex: 0 0 auto;
    width: 100%;
  }

  .ds-background .ds-image {
    position: unset;
    top: 0;
    margin: auto;
    text-align: center;
  }

  .ds-background .col-md-8 p br {
    display: none;
  }

  .executive-team .col-md-3 {
    flex: 0 0 auto;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .executive-team .member-content .linked-in {
    position: absolute;
    top: -320px;
    right: 15px;
  }

  .executive-team h3 {
    text-align: center;
    font-size: 30px;
    color: #000000;
    margin-bottom: 30px;
  }

  .ready-to-work {
    padding: 66% 13% 12.8%;
  }

  .ready-to-work .check {
    background: #fff;
    border-radius: 30px;
    text-align: center;
    padding: 20px 0;
    width: 260px;
    height: 60px;
    color: #000;
    font-size: 16px;
  }

  .ready-to-work p br {
    display: none;
  }

  .executive-team .member-content .details h4 {
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 0px;
  }

  .executive-team .member-content .details {
    position: absolute;
    bottom: 1px;
    color: #fff;
    padding: 10px 10px 5px;
    width: 100%;
    left: 0%;
  }

  .executive-team .team-member {
    max-width: 280px;
    margin: 20px auto;
    text-align: center;
  }

  .executive-team .team-member img.mw-95 {
    max-width: 100%;
  }

  .executive-team .member-content .details p br {
    display: none;
  }

  .certification {
    margin-bottom: 20px;
  }

}

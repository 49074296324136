.not-found-container {
    height: 800px;
    background: url(../assets/img/thanks/thanks-bg.png) no-repeat center top;
    background-size: 100%;
}


.not-found-container .thanks-content {
    position: absolute;
    left: 17%;
    top: 55%;
}

.not-found-container .thanks-content h1 {
    font-size: 45px;
    line-height: 45px;
    margin-bottom: 20px;
    color: #fff;
}

.go-home {
    background: #DEEAF3;
    border-radius: 30px;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 12px 30px;
    color: #0f3828;
    font-weight: 700;
    border: solid 1px #0F3828;
    width: 200px;
    text-transform: uppercase;
    cursor: pointer;
    margin: 20px 0;
    box-shadow: none;
}

.go-home:hover {
    background: #03DAC4;
    border-radius: 30px;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 12px 30px;
    color: #0F3828;
    font-weight: 700;
    border: solid 1px #0F3828;

    text-transform: uppercase;
}


@media only screen and (min-width: 1280px) and (max-width: 1440.99px) {
    .not-found-container .thanks-content {
        position: absolute;
        left: 17%;
        top: 300px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024.99px) {

    .not-found-container {
        height: 650px;
    }

    .not-found-container .thanks-content {
        position: absolute;
        left: 15%;
        top: 230px;
    }
}

@media only screen and (min-width:768px) and (max-width: 991.99px) {
    .not-found-container {
        height: 500px;
        background-size: 110%;
    }

    .not-found-container .thanks-content {
        position: absolute;
        left: 10%;
        top: 180px;
    }
}


@media only screen and (min-width:560px) and (max-width: 767.99px) {
    .not-found-container {
        height: 400px;
        background-size: 110%;
    }

    .not-found-container .thanks-content {
        position: absolute;
        left: 10%;
        top: 130px;
    }


    .not-found-container .thanks-content h1 {
        font-size: 30px;
        line-height: 25px;
        margin-bottom: 0px;
        color: #fff;
        text-align: center;
    }

    .go-home {
        background: #DEEAF3;
        border-radius: 30px;
        font-size: 15px;
        line-height: 25px;
        text-align: center;
        text-decoration: none;
        padding: 12px 30px;
        color: #0f3828;
        font-weight: 700;
        border: solid 1px #0F3828;
        width: 200px;
        text-transform: uppercase;
        cursor: pointer;
        margin: 20px 0;
        box-shadow: none;
    }
}

@media only screen and (min-width:460px) and (max-width: 559.99px) {
    .not-found-container {
        height: 330px;
        background-size: 110%;
    }

    .not-found-container .thanks-content {
        position: absolute;
        left: 10%;
        top: 130px;
    }
    .not-found-container .thanks-content h1 {
        font-size: 30px;
        line-height: 25px;
        margin-bottom: 0px;
        color: #fff;
        text-align: center;
    }

    .go-home {
        background: #DEEAF3;
        border-radius: 30px;
        font-size: 15px;
        line-height: 25px;
        text-align: center;
        text-decoration: none;
        padding: 12px 30px;
        color: #0f3828;
        font-weight: 700;
        border: solid 1px #0F3828;
        width: 200px;
        text-transform: uppercase;
        cursor: pointer;
        margin: 20px 0;
        box-shadow: none;
    }
}

@media only screen and (min-width:360px) and (max-width: 459.99px) {
    .not-found-container {
        height: 250px;
        background-size: 110%;
    }

    .not-found-container .thanks-content {
        position: absolute;
        left: 10%;
        top: 100px;
    }

    .not-found-container .thanks-content h1 {
        font-size: 30px;
        line-height: 25px;
        margin-bottom: 0px;
        color: #fff;
        text-align: center;
    }

    .go-home {
        background: #DEEAF3;
        border-radius: 30px;
        font-size: 15px;
        line-height: 25px;
        text-align: center;
        text-decoration: none;
        padding: 12px 30px;
        color: #0f3828;
        font-weight: 700;
        border: solid 1px #0F3828;
        width: 200px;
        text-transform: uppercase;
        cursor: pointer;
        margin: 20px 0;
        box-shadow: none;
    }
}

@media only screen and (min-width:320px) and (max-width: 359.99px) {
    .not-found-container {
        height: 230px;
        background-size: 110%;
    }

    .not-found-container .thanks-content {
        position: absolute;
        left: 10%;
        top: 100px;
    }
    .not-found-container .thanks-content h1 {
        font-size: 30px;
        line-height: 25px;
        margin-bottom: 0px;
        color: #fff;
        text-align: center;
    }

    .go-home {
        background: #DEEAF3;
        border-radius: 30px;
        font-size: 15px;
        line-height: 25px;
        text-align: center;
        text-decoration: none;
        padding: 12px 30px;
        color: #0f3828;
        font-weight: 700;
        border: solid 1px #0F3828;
        width: 200px;
        text-transform: uppercase;
        cursor: pointer;
        margin: 20px 0;
        box-shadow: none;
    }
}
.contact-banner {
  background: url(../assets/img/contactus/contact_banner.png) no-repeat center top;
  height: 700px;
  padding: 5% 0px 5%;
  background-size: cover;
  position: relative;
  margin: 0px auto;
}

.banner-content-contact {
  color: #fff;
  margin-left: 0%;
  margin-top: 26%;
}

.banner-content-contact h1 {
  font-size: 45px;
  line-height: 45px;
  margin-bottom: 20px;
}

.banner-content-contact p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
}

.contact-content {
  padding: 50px 0px;
  margin: 0px auto;
}

.contact-content h2 {
  font-size: 38px;
  text-align: center;
  color: #333333;
  margin-bottom: 20px;
}

.contact-content p {
  text-align: center;
  font-size: 17px;
  line-height: 26px;
  color: #333333;
  font-weight: 400;
}

.contact-content .jamaica {
  background: #FFF8F3;
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 20px
}

.location-details {
  padding: 30px 10px 30px 20px;
  text-align: left;
  line-height: 30px;
}

.location-details h4 {
  font-size: 30px;
  margin-bottom: 35px;
}

.location-details .addreess {
  text-align: left;
  margin: 10px 10px;
}

.location-details .addreess p {
  text-align: left;
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 30px;
}

.location-details .addreess p .icon {
  float: left;
  margin: 0px 20px 10px 0px;
}

.contact-content .southafrica {
  background: #F3F9FF;
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 20px
}

.contact-content .india {
  background: #F3FFFA;
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 20px
}

.contact-content .dubai {
  background: #FFF8F3;
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 20px
}

.contact-content .miami {
  background: #CEF8F8;
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 20px
}

.enqiry {
  background: #F6F8F9;
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px 50px;
  border-radius: 20px;
}

.enqiry p {
  text-align: left;
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 0px;
  color: #333;
}

.enqiry p strong {
  font-weight: 600;
}

.enqiry p a {
  color: #333;
  margin-left: 10px;
}

.contact-form {
  background: #020221;
  padding: 50px 0px;
  color: #fff;
}

#contact-form h3 {
  font-size: 40px;
  margin-bottom: 50px;
}

#contact-form input {
  background: #0B0B26;
  border-radius: 10px;
  height: 60px;
  border: solid 2px #242443;
  margin-bottom: 25px;
  color: #fff;
}

#contact-form textarea {
  background: #0B0B26;
  border-radius: 10px;
  height: 150px;
  border: solid 2px #242443;
  margin-bottom: 25px;
  color: #fff;
}

#contact-form .btn-orange {
  background-color: #03DAC4;
  color: #fff;
  width: 215px;
  height: 60px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  border-radius: 30px;
  margin-top: 20px;
}

#contact-form .btn-orange:hover {
  background-color: #0F3828;
}

#contact-form .error {
  font-size: 15px;
  color: #ff0000;
  margin: 0px 0px 10px;
}

.join-our-team {
  background: url(../assets/img/contactus/contact_cta_bg.png) no-repeat center top;
  padding: 11% 0px 0px;
  height: 459px;
  background-size: cover;
}

.join-our-team h3 {
  font-size: 40px;
  margin-bottom: 40px;
  text-align: center;
  color: #fff;
}

.join-our-team h3 span {
  color: #03DAC4;
}

.join-our-team-btn {
  background: #fff;
  border-radius: 30px;
  font-size: 15px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  padding: 15px 25px;
  color: #000;
  font-weight: 700;
  border: none;
  text-transform: uppercase;
  width: 263px;
  height: 60px;
  margin: auto;
  cursor: pointer;
}

.join-our-team-btn a {
  text-decoration: none;
  color: #000 !important;
}

.join-our-team-btn:hover,
.join-our-team-btn:hover a {
  background: #0F3828;
  color: #fff !important;
}

@media only screen and (min-width: 1280px) and (max-width: 1440.99px) {
  .banner-content-contact {
    color: #fff;
    margin-left: 0%;
    margin-top: 27%;
  }

}


@media only screen and (min-width: 992px) and (max-width: 1024.99px) {

  .banner-content-contact {
    color: #fff;
    margin-left: 0%;
    margin-top: 50%;
  }

  .banner-content-contact h1 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .contact-content h2 {
    font-size: 35px;
    margin-bottom: 20px;
  }

  .contact-content .jamaica .location-details h4,
  .contact-content .southafrica .location-details h4,
  .contact-content .india .location-details h4,
  .contact-content .dubai .location-details h4 {
    font-size: 25px;
    margin-bottom: 35px;
  }

  .contact-content .jamaica .location-details {
    padding: 20px 0px;
    text-align: left;
    line-height: 30px;
  }

  .contact-content .southafrica .location-details,
  .contact-content .india .location-details,
  .contact-content .dubai .location-details {
    padding: 20px 0px;
    text-align: left;
    line-height: 30px;
  }

  .contact-content .dubai .location-details .addreess p .icon,
  .contact-content .southafrica .location-details .addreess p .icon,
  .contact-content .india .location-details .addreess p .icon,
  .contact-content .jamaica .location-details .addreess p .icon {
    float: left;
    margin: 0px 20px 30px 0px;
  }

  .join-our-team h3 {
    font-size: 35px;
    margin-bottom: 40px;
    text-align: center;
    color: #fff;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.99px) {

  .banner-content-contact {
    color: #fff;
    margin-left: 0%;
    margin-top: 55%;
  }

  .banner-content-contact h1 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .contact-content h2 {
    font-size: 35px;
    margin-bottom: 20px;
  }

  .contact-content .jamaica .location-details h4,
  .contact-content .southafrica .location-details h4,
  .contact-content .india .location-details h4,
  .contact-content .dubai .location-details h4 {
    font-size: 25px;
    margin-bottom: 35px;
  }

  .contact-content .jamaica .location-details {
    padding: 20px 0px;
    text-align: left;
    line-height: 30px;
  }

  .contact-content .southafrica .location-details,
  .contact-content .india .location-details,
  .contact-content .dubai .location-details {
    padding: 20px 0px;
    text-align: left;
    line-height: 30px;
  }

  .contact-content .dubai .location-details .addreess p .icon,
  .contact-content .southafrica .location-details .addreess p .icon,
  .contact-content .india .location-details .addreess p .icon,
  .contact-content .jamaica .location-details .addreess p .icon {
    float: left;
    margin: 0px 20px 30px 0px;
  }

  .join-our-team h3 {
    font-size: 35px;
    margin-bottom: 40px;
    text-align: center;
    color: #fff;
  }
}


@media only screen and (min-width:560px) and (max-width: 767.99px) {

  .banner-content-contact {
    color: #fff;
    margin-left: 0%;
    margin-top: 85%;
  }

  .banner-content-contact h1 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .banner-content-contact h1 br {
    display: none;
  }

  .banner-content-contact p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 300;
  }

  .banner-content-contact p br,
  .contact-content p br {
    display: none;
  }

  .contact-content h2 {
    font-size: 35px;
    margin-bottom: 20px;
  }

  .contact-content .jamaica .location-details h4,
  .contact-content .southafrica .location-details h4,
  .contact-content .india .location-details h4,
  .contact-content .dubai .location-details h4 {
    font-size: 25px;
    margin-bottom: 35px;
  }

  .contact-content .jamaica .location-details {
    padding: 20px 0px;
    text-align: left;
    line-height: 30px;
  }

  .contact-content .southafrica .location-details,
  .contact-content .india .location-details,
  .contact-content .dubai .location-details {
    padding: 20px 0px;
    text-align: left;
    line-height: 30px;
  }

  .contact-content .dubai .location-details .addreess p .icon,
  .contact-content .southafrica .location-details .addreess p .icon,
  .contact-content .india .location-details .addreess p .icon,
  .contact-content .jamaica .location-details .addreess p .icon {
    float: left;
    margin: 0px 20px 30px 0px;
  }

  .join-our-team h3 {
    font-size: 35px;
    margin-bottom: 40px;
    text-align: center;
    color: #fff;
  }

  .join-our-team h3 br {
    display: none;
  }

  #contact-form h3 {
    font-size: 35px;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width:460px) and (max-width: 559.99px) {

  .banner-content-contact {
    color: #fff;
    margin-left: 0%;
    margin-top: 95%;
  }

  .banner-content-contact h1 {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .banner-content-contact h1 br {
    display: none;
  }

  .banner-content-contact p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 300;
  }

  .banner-content-contact p br,
  .contact-content p br {
    display: none;
  }

  .contact-content h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .contact-content .jamaica .location-details h4,
  .contact-content .southafrica .location-details h4,
  .contact-content .india .location-details h4,
  .contact-content .dubai .location-details h4 {
    font-size: 25px;
    margin-bottom: 35px;
  }

  .contact-content .jamaica .location-details {
    padding: 20px 0px;
    text-align: left;
    line-height: 30px;
  }

  .contact-content .southafrica .location-details,
  .contact-content .india .location-details,
  .contact-content .dubai .location-details {
    padding: 20px 0px;
    text-align: left;
    line-height: 30px;
  }

  .contact-content .dubai .location-details .addreess p .icon,
  .contact-content .southafrica .location-details .addreess p .icon,
  .contact-content .india .location-details .addreess p .icon,
  .contact-content .jamaica .location-details .addreess p .icon {
    float: left;
    margin: 0px 20px 30px 0px;
  }

  .join-our-team h3 {
    font-size: 30px;
    margin-bottom: 40px;
    text-align: center;
    color: #fff;
  }

  .join-our-team h3 br {
    display: none;
  }

  #contact-form h3 {
    font-size: 30px;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width:360px) and (max-width: 459.99px) {

  .banner-content-contact {
    color: #fff;
    margin-left: 0%;
    margin-top: 95%;
  }

  .banner-content-contact h1 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .banner-content-contact h1 br {
    display: none;
  }

  .banner-content-contact p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 300;
  }

  .banner-content-contact p br,
  .contact-content p br {
    display: none;
  }

  .contact-content h2 {
    font-size: 25px;
    margin-bottom: 20px;
  }

  .contact-content .jamaica .location-details h4,
  .contact-content .southafrica .location-details h4,
  .contact-content .india .location-details h4,
  .contact-content .dubai .location-details h4 {
    font-size: 20px;
    margin-bottom: 35px;
  }

  .contact-content .jamaica .location-details {
    padding: 20px 0px;
    text-align: left;
    line-height: 30px;
  }

  .contact-content .southafrica .location-details,
  .contact-content .india .location-details,
  .contact-content .dubai .location-details {
    padding: 20px 0px;
    text-align: left;
    line-height: 30px;
  }

  .contact-content .dubai .location-details .addreess p .icon,
  .contact-content .southafrica .location-details .addreess p .icon,
  .contact-content .india .location-details .addreess p .icon,
  .contact-content .jamaica .location-details .addreess p .icon {
    float: left;
    margin: 0px 20px 30px 0px;
  }

  .join-our-team h3 {
    font-size: 25px;
    margin-bottom: 40px;
    text-align: center;
    color: #fff;
  }

  .join-our-team h3 br {
    display: none;
  }

  #contact-form h3 {
    font-size: 25px;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width:320px) and (max-width: 359.99px) {

  .banner-content-contact {
    color: #fff;
    margin-left: 0%;
    margin-top: 95%;
  }

  .banner-content-contact h1 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .banner-content-contact h1 br {
    display: none;
  }

  .banner-content-contact p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 300;
  }

  .banner-content-contact p br,
  .contact-content p br {
    display: none;
  }

  .contact-content h2 {
    font-size: 25px;
    margin-bottom: 20px;
  }

  .contact-content .jamaica .location-details h4,
  .contact-content .southafrica .location-details h4,
  .contact-content .india .location-details h4,
  .contact-content .dubai .location-details h4 {
    font-size: 20px;
    margin-bottom: 35px;
  }

  .contact-content .jamaica .location-details {
    padding: 20px 0px;
    text-align: left;
    line-height: 30px;
  }

  .contact-content .southafrica .location-details,
  .contact-content .india .location-details,
  .contact-content .dubai .location-details {
    padding: 20px 0px;
    text-align: left;
    line-height: 30px;
  }

  .contact-content .dubai .location-details .addreess p .icon,
  .contact-content .southafrica .location-details .addreess p .icon,
  .contact-content .india .location-details .addreess p .icon,
  .contact-content .jamaica .location-details .addreess p .icon {
    float: left;
    margin: 0px 20px 30px 0px;
  }

  .join-our-team h3 {
    font-size: 25px;
    margin-bottom: 40px;
    text-align: center;
    color: #fff;
  }

  .join-our-team h3 br {
    display: none;
  }

  #contact-form h3 {
    font-size: 25px;
    margin-bottom: 50px;
  }
}

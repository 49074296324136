.footer {
    background: #0B0923;
    text-align: left;
    font-size: 16px;
    color: #fff;
}

.footer .footer-link {
    padding: 3% 0% 0%;
}

.footer .footer-link .group-of-companies {
    padding: 2% 0% 1%;
    border-radius: 15px;
    width: 100%;
    margin: 20px auto 0px;
}

.footer-bottom .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
    text-align: center !important;
}

.footer .footer-link .container .col {
    flex: 1 0 100%;
}

.footer .footer-link .container .col.mx-4 {
    margin: 0px 0px !important;
}

.footer .footer-link .col:nth-child(3) {
    margin: 0px 0px !important;
}

.footer .footer-link .iso .col-md-6,
.footer .footer-link .iso .col-md-12 {
    flex: 0 0 auto;
}

.footer .footer-link h5 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.footer .footer-link p {
    margin-bottom: 5px;
}

.footer .footer-link p a {
    text-decoration: none;
    color: #9C9AB4;
    line-height: 32px;
}

.footer .footer-link p a:hover {
    text-decoration: none;
    color: #fff;
}

.footer .footer-link .social {
    margin: 30px 0px;
}

.footer .footer-link .address img.location {
    float: left;
    margin-right: 20px;
    margin-bottom: 150px;
}

.footer .footer-link .address img.phone {
    float: left;
    margin-right: 20px;
    margin-bottom: 10px;
}

.footer .footer-link .address p {
    vertical-align: top;
    margin-bottom: 20px;
    clear: both;
}

.footer .footer-link .group-of-companies {
    background: #1A193B;
    padding: 2% 2.5% 1%;
    border-radius: 15px;
    margin-top: 50px;
}

.footer .footer-link .group-of-companies p {
    margin-bottom: 0px;
    color: #C7D4E2;
    font-size: 12px;
    line-height: 30px;
}

.footer .footer-link .group-of-companies .col {
    flex: 1 0 0%;
    margin: 10px 0px 0px 0px;
}

.footer .footer-link .group-of-companies .col:nth-child(1) {
    margin-left: 0px;
}

.footer .footer-link .group-of-companies .logo-1 {
    padding: 0px 0px 0px px;
    color: #C7D4E2;
}

.footer .footer-link a.logo-2 {
    padding: 5px 0px 20px 0px;
    display: block;
}

.footer .footer-link .iso {
    background: #1A193B;
    padding: 4% 0% 4%;
    border-radius: 15px;
    margin-top: 50px;
}

.footer .footer-link .iso p {
    text-align: center;
    color: #03DAC4;
    font-size: 13px;
    padding: 2% 0% 1.5%;
}

.cookies-btn {
    background-color: #005293;
    height: 43px;
    width: 43px;
    border-radius: 100%;
    position: fixed;
    bottom: 46px;
    left: 25px;
    cursor: pointer;
    border: solid 1px #dcdcdc;
    padding: 3px;
    z-index: 999;
    -webkit-box-shadow: 0px 0px 26px 3px rgba(122, 122, 122, 0.46);
    -moz-box-shadow: 0px 0px 26px 3px rgba(122, 122, 122, 0.46);
    box-shadow: 0px 0px 26px 3px rgba(122, 122, 122, 0.46);
}

.cookie-overlay h4 {
    color: #0F3828;
    font-weight: 600;
    font-size: 1rem !important;
    text-align: left;
}

.cookie-overlay .bg-white {
    position: fixed;
    bottom: 0rem;
    left: 0%;
    right: 0%;
    background: #fff;
    z-index: 9999;
    line-height: 20px;
    font-size: 14px;
    border-radius: 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: left;
    width: auto;
}

.cookie-overlay a {
    text-decoration: underline;
    color: #0F3828 !important;
    margin-left: 20px;
}

.btn-orange {
    background: #03DAC4;
    color: #fff;
    margin: auto;
    display: inline-block;
    font-weight: 700;
    width: 150px;
    height: 45px;
    font-size: 15px;
}

.cookie-overlay .bg-white .btn-success {
    background: none !important;
    color: #0F3828;
    margin: auto;
    display: inline-block;
    font-weight: 700;
    width: 150px;
    height: 45px;
    border: none !important;
    text-decoration: underline;
    font-size: 15px;
}

.cookiesSettingsModal .modal-body h5 {
    color: #0F3828;
    font-size: 1rem;
    font-weight: 600;
}

.cookiesSettingsModal a.card-title {
    text-decoration: none;
    color: #005293 !important;
}

.cookiesSettingsModal .btn-close {
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'%23fff\'%3e%3cpath d=\'M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z\'/%3e%3c/svg%3e');
    background-color: #0F3828;
    opacity: unset;
    border-radius: 50px;
}

.cookiesSettingsModal .accordion-button::before {
    content: '';
    transition: all 0.5s;
    width: 1em;
    height: 1em;
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'none\' stroke=\'%23212529\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3e%3cpath d=\'M2 8h12M8 2v12\'/%3e%3c/svg%3e');
    margin-right: 1em;
}

.cookiesSettingsModal .accordion-button:not(.collapsed) {
    background-color: transparent;
}

.cookiesSettingsModal .accordion-button:focus {
    box-shadow: unset;
}

.cookiesSettingsModal .accordion-button:not(.collapsed)::before {
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'none\' stroke=\'%23212529\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3e%3cpath d=\'M2 8h12\'/%3e%3c/svg%3e');
    transform: rotate(-180deg);
}

.cookiesSettingsModal .accordion-button::after {
    display: none;
}

.cookiesSettingsModal a.card-title {
    text-decoration: none;
    color: #03DAC4 !important;
    margin-left: 20px;
}

.modal-body {
    font-size: 16px;
}

.showrocket {
    background: url(../assets/img/footer/back-top.png) no-repeat 50% 0;
    position: fixed;
    bottom: 50px;
    width: 26px;
    height: 48px;
    right: 25px;
    cursor: pointer;
}

.showrocket:hover {
    background-position: 50% -62px;
    cursor: pointer;
}

@media only screen and (min-width:560px) and (max-width: 767.99px) {

    .footer .footer-link .col-md-3,
    .footer .footer-link .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        text-align: center;
    }

    .footer .footer-link .address img.location,
    .footer .footer-link .address img.phone {
        float: none !important;
        display: block;
        margin: 10px auto !important;
    }

    .footer .footer-link .group-of-companies {

        padding: 2% 0% 1%;
        border-radius: 15px;
        width: 90%;
        margin: 20px auto 0px;
        text-align: center;
    }

    .footer .footer-link .group-of-companies .col-8,
    .footer .footer-link .group-of-companies .col-4 {
        flex: 0 0 auto;
        width: 100%;
        margin: 0px auto;
        text-align: center;
    }

    .group-of-companies img {
        width: auto;
        margin: 10px 20px;
    }

    .footer .footer-link .col-md-2 {
        flex: 0 0 auto;
        width: 100%;
        margin: 0px auto;
        text-align: center;
    }

    .footer .footer-link .iso {
        background: #1A193B;
        padding: 2% 0% 2%;
        border-radius: 15px;
        margin: 50px auto;
        text-align: center;
        width: 90%;
    }

    .footer .footer-link .group-of-companies p {
        text-align: center !important;
    }

    .footer .footer-link .iso .col-md-6,
    .footer .footer-link .iso .col-md-12 {
        flex: 0 0 auto;
        /* width: 33.33%; */
    }

    .footer .footer-link .row .col-md-10 {
        flex: 0 0 auto;
        width: 100%;
    }

    .flex-sm-row {
        flex-direction: unset;
        display: block !important;
        padding-left: 0px !important;
    }

    .footer .footer-link .footer-bottom {
        background: #06041A;
        padding: 10px 0% 10px;
        color: #B4C1CC;
        display: block;
        font-size: 14px;
    }


    .footer-bottom .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        text-align: center !important;
    }

    .footer .footer-link .container .col {
        flex: 1 0 100%;
    }

    .footer .footer-link .container .col.mx-4 {
        margin: 0px 0px !important;
    }

    .footer .footer-link .col:nth-child(3) {
        margin: 0px 0px !important;
    }

    .footer .footer-link h5 {
        font-size: 18px;
        color: #fff;
        margin: 15px 0px 10px !important;
        text-align: center;
    }

    // .footer .footer-link p {
    //     margin-bottom: 0px;
    //     display: block;
    //     margin-right: 15px;
    //     text-align: center !important;
    // }

    .footer .footer-link p {
      margin-bottom: 0;
      display: inline;
      margin: 0 13px;
      text-align: center !important;
    }

    .footer .footer-link p br {
      display: none;
    }

    .footer .footer-link .social a img{
      margin: 0px 20px;
  }

}

@media only screen and (min-width:460px) and (max-width: 559.99px) {

    .footer .footer-link .col-md-3,
    .footer .footer-link .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        text-align: center;
    }

    .footer .footer-link .col-md-4 .row .col-md-6{
      text-align: center;
    }

    .footer .footer-link .address img.location,
    .footer .footer-link .address img.phone {
        float: none !important;
        display: block;
        margin: 10px auto !important;
    }

    .footer .footer-link .group-of-companies {

        padding: 2% 0% 1%;
        border-radius: 15px;
        width: 100%;
        margin: 20px auto 0px;
        text-align: center;
    }


    .footer .footer-link a.logo-2 {
        padding: 5px 0px 0px 0px;
        display: block;
    }

    .footer .footer-link .group-of-companies .col-8,
    .footer .footer-link .group-of-companies .col-4 {
        flex: 0 0 auto;
        width: 100%;
        margin: 0px auto;
        text-align: center;
    }

    .group-of-companies img {
        width: auto;
        margin: 10px 20px 10px;
    }

    .footer .footer-link .col-md-2 {
        flex: 0 0 auto;
        width: 100%;
        margin: 0px auto;
    }

    .footer .footer-link .iso {
        background: #1A193B;
        padding: 2% 0% 2%;
        border-radius: 15px;
        margin: 50px auto;
        text-align: center;
        width: 100%;
    }

    .footer .footer-link .group-of-companies p {
        text-align: center !important;
    }

    .footer .footer-link .iso .col-md-6,
    .footer .footer-link .iso .col-md-12 {
        flex: 0 0 auto;
        /* width: 33.33%; */
    }

    .footer .footer-link .row .col-md-10 {
        flex: 0 0 auto;
        width: 100%;
    }

    .flex-sm-row {
        flex-direction: unset;
        display: block !important;
        padding-left: 0px !important;
    }

    .footer .footer-link .footer-bottom {
        background: #06041A;
        padding: 10px 0% 10px;
        color: #B4C1CC;
        display: block;
        font-size: 14px;
    }


    .footer-bottom .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        text-align: center !important;
    }

    .footer .footer-link .container .col {
        flex: 1 0 100%;
    }

    .footer .footer-link .container .col.mx-4 {
        margin: 0px 0px !important;
    }

    .footer .footer-link .col:nth-child(3) {
        margin: 0px 0px !important;
    }

    .footer .footer-link h5 {
        font-size: 18px;
        color: #fff;
        margin: 15px 0px 10px !important;
        text-align: center;
    }

    .footer .footer-link p {
        display: block;
        margin: 0px 15px;
        text-align: center !important;
    }
    .footer .footer-link .social a img{
      margin: 0px 20px;
  }
}

@media only screen and (min-width:360px) and (max-width: 459.99px) {

    .footer .footer-link .col-md-3,
    .footer .footer-link .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        text-align: center;
    }

    .footer .footer-link .address img.location,
    .footer .footer-link .address img.phone {
        float: none !important;
        display: block;
        margin: 10px auto !important;
    }

    .footer .footer-link .group-of-companies {

        padding: 2% 0% 1%;
        border-radius: 15px;
        width: 100%;
        margin: 20px auto 0px;
        text-align: center;
    }


    .footer .footer-link a.logo-2 {
        padding: 5px 0px 0px 0px;
        display: block;
    }

    .footer .footer-link .group-of-companies .col-8,
    .footer .footer-link .group-of-companies .col-4 {
        flex: 0 0 auto;
        width: 100%;
        margin: 0px auto;
        text-align: center;
    }

    .group-of-companies img {
        width: auto;
        margin: 10px 20px 10px;
    }

    .footer .footer-link .col-md-2 {
        flex: 0 0 auto;
        width: 100%;
        margin: 0px auto;
    }

    .footer .footer-link .iso {
        background: #1A193B;
        padding: 2% 0% 2%;
        border-radius: 15px;
        margin: 50px auto;
        text-align: center;
        width: 100%;
    }

    .footer .footer-link .group-of-companies p {
        text-align: center !important;
    }

    .footer .footer-link .iso .col-md-6,
    .footer .footer-link .iso .col-md-12 {
        flex: 0 0 auto;
        /* width: 33.33%; */
    }

    .footer .footer-link .row .col-md-10 {
        flex: 0 0 auto;
        width: 100%;
    }

    .flex-sm-row {
        flex-direction: unset;
        display: inline-block !important;
        padding-left: 0px !important;
    }

    .footer .footer-link .footer-bottom {
        background: #06041A;
        padding: 10px 0% 10px;
        color: #B4C1CC;
        display: block;
        font-size: 14px;
    }


    .footer-bottom .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        text-align: center !important;
    }

    .footer .footer-link .container .col {
        flex: 1 0 100%;
    }

    .footer .footer-link .container .col.mx-4 {
        margin: 0px 0px !important;
    }

    .footer .footer-link .col:nth-child(3) {
        margin: 0px 0px !important;
    }

    .footer .footer-link h5 {
        font-size: 18px;
        color: #fff;
        margin: 15px 0px 10px !important;
        text-align: center;
    }

    .footer .footer-link p {
        margin-bottom: 0px;
        display: block;
        margin-right: 15px;
        text-align: center !important;
    }
    .footer .footer-link .social a img{
      margin: 0px 20px;
  }
}

@media only screen and (min-width:320px) and (max-width: 359.99px) {

    .footer .footer-link .col-md-3,
    .footer .footer-link .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        text-align: center;
    }

    .footer .footer-link .address img.location,
    .footer .footer-link .address img.phone {
        float: none !important;
        display: block;
        margin: 10px auto !important;
    }

    .footer .footer-link .group-of-companies {

        padding: 2% 0% 1%;
        border-radius: 15px;
        width: 100%;
        margin: 20px auto 0px;
        text-align: center;
    }

    .footer .footer-link .group-of-companies .col-8,
    .footer .footer-link .group-of-companies .col-4 {
        flex: 0 0 auto;
        width: 100%;
        margin: 0px auto;
        text-align: center;
    }

    .group-of-companies img {
        width: auto;
        margin: 10px 20px;
    }

    .footer .footer-link .col-md-2 {
        flex: 0 0 auto;
        width: 100%;
        margin: 0px auto;
    }

    .footer .footer-link .iso {
        background: #1A193B;
        padding: 2% 0% 2%;
        border-radius: 15px;
        margin: 50px auto;
        text-align: center;
        width: 100%;
    }

    .footer .footer-link .group-of-companies p {
        text-align: center !important;
    }

    .footer .footer-link .iso .col-md-6,
    .footer .footer-link .iso .col-md-12 {
        flex: 0 0 auto;
        /* width: 33.33%; */
    }

    .footer .footer-link .row .col-md-10 {
        flex: 0 0 auto;
        width: 100%;
    }

    .flex-sm-row {
        flex-direction: unset;
        display: inline-block !important;
        padding-left: 0px !important;
    }

    .footer .footer-link .footer-bottom {
        background: #06041A;
        padding: 10px 0% 10px;
        color: #B4C1CC;
        display: block;
        font-size: 14px;
    }


    .footer-bottom .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        text-align: center !important;
    }

    .footer .footer-link .container .col {
        flex: 1 0 100%;
    }

    .footer .footer-link .container .col.mx-4 {
        margin: 0px 0px !important;
    }

    .footer .footer-link .col:nth-child(3) {
        margin: 0px 0px !important;
    }

    .footer .footer-link h5 {
        font-size: 18px;
        color: #fff;
        margin: 15px 0px 10px !important;
        text-align: center;
    }

    .footer .footer-link p {
        margin-bottom: 0px;
        display: block;
        margin-right: 15px;
        text-align: center !important;
    }
    .footer .footer-link .social a img{
      margin: 0px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024.99px) {

    .container-xxl,
    .container {
        max-width: 95%;
        width: 100%;
        --bs-gutter-x: 0;
        --bs-gutter-y: 0;
    }

    .footer .footer-link .row .col-md-10 {
        flex: 0 0 auto;
        width: 100%;
    }

    .footer .footer-link .iso {
        background: #1A193B;
        padding: 2% 0% 2%;
        border-radius: 15px;
        margin: 50px auto;
        width: 280px;
        text-align: center;
    }

    .footer .footer-link .col-md-2 {
        flex: 0 0 auto;
        // width: 100%;
        margin: 0px auto;
    }
}

.policy-banner {
    background: url(../assets/img/privacy-cookie/tandc_cookie_banner.png) no-repeat center top;
    max-height: 800px;
    padding: 14.5% 0px;
}

.banner-policy-content {
    text-align: center;
    color: #fff;
    padding: 50px 0px;
}

.p-title {
    font-size: 1.05rem;
    line-height: 1.9rem;
    color: #333;
}

.cookies-policy .nav-tabs .nav-item.show .nav-link,
.cookies-policy .nav-tabs .nav-link.active {
    color: #ef6000;
    background-color: #fff;
    border-bottom: 3px solid #ef6000;
    outline: none;
}

.cookies-policy ul {
    cursor: pointer;
}

.cookies-policy h5 {
    color: #005293;
    font-weight: 700;
}

.cookies-policy .table-bordered thead th {
    color: #ef6000;
}
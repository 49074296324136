.inside-tag {
  display: flex;
  height: 100%;
  align-items: flex-end;
  background: linear-gradient(#00000000, #050520);
  border-radius: 15px;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.header-img img,
.header-img {
  height: 550px !important;
}

.header-img img,
.header-img {
  border-radius: 0px !important;
}

.video-blog video,
.add-class video,
.inside-tag .abs-img {
  object-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  border-radius: 15px;
}

.bottom-view h3 span {
  background: #03dac4;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
}

.bottom-view h3 {
  font-weight: 800;
  font-size: 40px;
  line-height: 45px;
  font-family: 'gt_walsheimbold', sans-serif;
  display: block;
  color: #fff;
}

.bottom-view {
  text-align: center;
  margin-top: 22%;
}

.share-btn:hover {
  background-color: #fff;
}

.blog-list-col h3 {
  font-family: 'gt_walsheimbold', sans-serif;
  color: #333333;
  margin-bottom: 40px;
  font-weight: 800;
  text-align: center;
  position: relative;
  font-size: 35px;
}

.three-col .post-main {
  padding: 10px;
}

.three-col .post-main h4 {
  color: #fff;
  font-size: 15px;
  width: 95%;
  line-height: 22px;
  margin: 0px;
}

.three-col .post-main ul {
  margin-bottom: 10px;
}

.three-col .post-main ul li {
  display: flex;
  gap: 5px;
  align-items: center;
  color: #fff;
  font-size: 13px;
  font-family: 'gt_walsheimnormal', sans-serif;
}

.three-col .post-main ul li img {
  width: 12px;
  margin-top: -2px;
}

.share-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #0052931C;
  color: #005193;
  border: 1px solid #005193;
  border-radius: 20px;
  padding: 3px 13px;
}

.share-btn:hover {
  background-color: #fff;
}

.inside h3 {
  color: #000;
  margin-bottom: 15px;
  width: 75%;
  font-family: 'gt_walsheimmedium', sans-serif;
}

.list-blg .col-md-4 img,
.list-blg .col-md-4 video {
  height: 270px;
  position: unset;
  object-fit: cover;
  width: 100%;
  object-position: center;
  border-radius: 15px;
}

.list-blg video {
  width: 100%;
  border-radius: 15px;
  height: 100%;
}

.list-blg h3 {
  font-family: 'gt_walsheimbold', sans-serif;
  color: #333333;
  font-size: 25px;
  margin-bottom: 0px;
  font-weight: 800;
  display: inline-block;
  width: 100%;
  position: relative;
}

.list-blg h3::after {
  width: 50%;
  content: " ";
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  background-size: cover;
}

.list-screen.three-col .post-main {
  padding: 20px;
  width: 100%;
  margin-top: auto;
}

.list-screen.three-col .blog-img-1 {
  height: 500px;
  cursor: pointer;
}

.list-screen.three-col .post-main h4 {
  color: #dbdbdb;
  font-size: 15px;
  width: 95%;
  line-height: 22px;
  margin: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-description {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden !important;
}

.close-icon {
  position: absolute;
  top: 25%;
  right: 11%;
  z-index: 9;
  color: #f00;
  cursor: pointer;
}

.search-container-xl img {
  width: 15px;
  position: absolute;
  top: 35%;
  right: 4%;
}

.search-container-xl {
  position: relative;
}

.share-blog a:hover img {
  opacity: 0.5;
}

.weight-user {
  font-weight: 500;
  color: #fff;
  font-family: 'gt_walsheimbold', sans-serif;
  font-size: 26px;
  cursor: pointer;
}

.view-user-date ul {
  display: flex;
  list-style: none;
  padding: 0px;
}

.h-250 {
  height: 245px !important;
  margin-bottom: 10px;
}
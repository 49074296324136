.carousel-inner {
    background: #050521;
}

.carousel-item img {
    vertical-align: top;
}

.carousel-item img {
  aspect-ratio: auto 1920 / 1080;
}

.carousel-item h1 {
    color: #6DFAEC;
    font-size: 38px;
    text-align: left;
    font-weight: 700;
    margin: 0px auto 20px;
    padding-bottom: 5px;
}

.carousel-item p {
    text-align: left;
    font-size: 22px;
    line-height: 32px;
    font-weight: 300;
}

.carousel-caption {
    position: absolute;
    color: #fff;
    text-align: left;
    margin: 0% auto 0px;
    padding-bottom: 0%;
    bottom: 35%;
    left: 40px;
}

.carousel-indicators .active {
    opacity: 1 !important;
    width: 24px !important;
    height: 8px !important;
    background-color: #6DFAEC !important;
    border-radius: 4px !important;
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 8px;
    height: 8px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    border: 0;
    border-radius: 50%;
    opacity: .5;
    transition: opacity .6s ease;
}

.serve_humanity {
    padding: 5% 0px 10.5%;
    margin: 0px auto;
    background: url(../assets/img/home/orange-bg.webp) no-repeat bottom center #fff;
    background-size: contain;
}

.serve_humanity .title-bg {
    background: url(../assets/img/home/text-bg.png) no-repeat center 10px;
    background-size: contain;
}

.serve_humanity h2 {
    font-size: 33px;
    color: #333333;
    text-align: center;
    margin-bottom: 0px;
    font-weight: 700;
}

.serve_humanity h2 span {
    color: #6DFAEC;
}

.serve_humanity_content {
    margin: 5% auto 3%;
    text-align: center;
}

.serve_humanity_content p {
    text-align: center;
    font-size: 17.5px;
    line-height: 26px;
    color: #333333;
    font-weight: 400;
}

.serve_humanity .points {
    background: #fff;
    width: 220px;
    height: 69px;
    line-height: 43px;
    text-align: center;
    padding: 10px 10px;
    margin: 10px 13px;
    color: #333333;
    border: solid 2px #03DAC4;
    border-radius: 50px;
}

.hire-on-demond {
    background: url(../assets/img/home/blue-img.png) no-repeat center top #F9FDFF;
    padding: 5% 0px 3%;
    margin: 0px auto;
}

.hire-on-demond .hire-content {
    margin: 0px 0px 0px 20%;
}

.hire-on-demond .hire-content h2 {
    color: #03DAC4;
    font-size: 33px;
    text-align: left;
    font-weight: 700;
    width: 65%;
}

.hire-on-demond .hire-content h3 {
    font-size: 33px;
    color: #333333;
    text-align: left;
    margin-bottom: 20px;
    font-weight: 700;
}

.hire-on-demond .hire-content p {
    text-align: left;
    font-size: 18px;
    line-height: 30px;
    color: #333333;
    font-weight: 400;
}

.hire-on-demond .know-more {
    background: #DEEAF3;
    border-radius: 30px;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 12px 30px;
    color: #0F3828;
    font-weight: 700;
    border: solid 1px #0F3828;
    width: 200px;
    text-transform: uppercase;
    cursor: pointer;
    margin: 20px 0px;
}

.hire-on-demond .know-more:hover {
    background: #03DAC4;
    border-radius: 30px;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 12px 30px;
    color: #fff;
    font-weight: 700;
    border: solid 1px #0F3828;
    width: 200px;
    text-transform: uppercase;
    transition: 0.6s;
}

.hire-on-demond .know-more a {
    color: #0F3828;
    text-decoration: none;
}

.hire-on-demond .know-more a:hover,
.hire-on-demond .know-more:hover a {
    color: #fff;
}

.pioneering {
    background: #fff;
    padding: 5% 0px 3%;
    margin: 0px auto;
}

.pioneering .pioneering-content {
    margin: 0px 20% 0px 16%;
}

.pioneering .pioneering-content h2 {
    font-size: 33px;
    line-height: 43px;
    color: #333333;
    text-align: left;
    margin-bottom: 20px;
    font-weight: 700;
}

.pioneering .pioneering-content h2 span {
    color: #03DAC4;
}

.pioneering .pioneering-content p {
    text-align: left;
    font-size: 18px;
    line-height: 30px;
    color: #333333;
    font-weight: 400;
}

.pioneering .pioneering-content .know-more {
    background: #DEEAF3;
    border-radius: 30px;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 12px 30px;
    color: #0F3828;
    font-weight: 700;
    border: solid 1px #0F3828;
    width: 200px;
    text-transform: uppercase;
    cursor: pointer;
    margin: 20px 0px;
}

.pioneering .pioneering-content .know-more a {
    color: #0F3828;
    text-decoration: none;
}

.pioneering .pioneering-content .know-more:hover {
    background: #03DAC4;
    border-radius: 30px;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 12px 30px;
    color: #fff;
    font-weight: 700;
    border: solid 1px #0F3828;
    width: 200px;
    text-transform: uppercase;
    transition: 0.6s;
}

.pioneering .pioneering-content .know-more a:hover,
.pioneering .pioneering-content .know-more:hover a {
    color: #fff;
}

.cybersecurity {
    background: url(../assets/img/home/next-gen.png) no-repeat center top #050520;
    padding: 7% 0px 0%;
    margin: 0px auto;
    color: #fff;
}

.cybersecurity .cybersecurity-content {
    margin: 7% 0px 0px;
    padding-right: 10%;
}

.cybersecurity .cybersecurity-content h2 {
    font-size: 33px;
    color: #6DFAEC;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 700;
}

.cybersecurity .cybersecurity-content p {
    text-align: left;
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 20px;
}

.cybersecurity .cybersecurity-content .secure-now {
    background: #6DFAEC;
    border-radius: 30px;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    text-decoration: none;
    padding: 12px 20px;
    color: #0F3828;
    font-weight: 700;
    border: solid 1px #6DFAEC;
    width: 180px;
    text-transform: uppercase;
}

.cybersecurity .cybersecurity-content .secure-now:hover {
    background: #fff;
    border-radius: 30px;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    text-decoration: none;
    padding: 12px 20px;
    color: #0F3828;
    font-weight: 700;
    border: solid 1px #0F3828;
    width: 180px;
    text-transform: uppercase;
    transition: 0.6s;
}

.cybersecurity .cybersecurity-content .secure-now a {
    color: #fff;
    text-decoration: none;
}

.cybersecurity .cybersecurity-content .signup-form {
    margin: 20px 0px;
}

.cybersecurity .cybersecurity-content .signup-form input {
    background: #444458;
    border-radius: 30px;
    height: 60px;
    margin-bottom: 0px;
    color: #fff;
    width: 100%;
    padding: 10px 20px;
    border: none;
}

.cybersecurity .cybersecurity-content .signup-form input:focus-visible {
    box-shadow: none;
    outline: none;
}

.what-we-do {
  background:#fff;
  background-size: contain;
    padding: 5% 0px 5%;
    margin: 0px auto;
}

.what-we-do .container {
  background: url(../assets/img/home/text-bg-what-we-do.png) no-repeat right 10px;
}

.what-we-do h2 {
    color: #333333;
    font-size: 33px;
    text-align: left;
    font-weight: 700;
}

.what-we-do h2 span {
    color: #03DAC4;
}

.what-we-do h3 {
    font-size: 33px;
    color: #333333;
    text-align: left;
    margin-bottom: 0px;
}

.what-we-do .tab-bg {
	background: url(../assets/img/home/tab-content-bg.png) no-repeat center -25px #fff;
	background-size: 88%;
}


.what-we-do nav.tabs {
    margin: 0 auto;
    background: #fff;
    max-width: 1360px;
}

.what-we-do li.nav-item {
    margin: 0;
    padding: 0;
}

.what-we-do .tab-content.active {
    padding: 15px;
    position: relative;
}

.what-we-do .tabs-container {
    position: relative;
    padding: 3% 0px 2%;
    margin: auto;
}

.what-we-do .tabs-container .container {
  background: none;
}

.what-we-do .tab-content .tab-description h3 {
    color: #fff;
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: 700;
}
.what-we-do .tab-content {
	position: relative;
}
.what-we-do .tab-content .tab-description {
    text-align: left;
    color: #fff;
    position: absolute;
    top: 70px;
    left: 70px;
    width: 95%;
}

.what-we-do .tab-content .tab-description p {
    font-size: 18px;
    width: 100%;
    margin-bottom: 25px;
    color: #fff;
}

.what-we-do .tab-content .tab-description p img {
  margin-right: 20px;
  margin-bottom: 20px;
  vertical-align: top;
  float: left;

}

.what-we-do .tab-content .tab-description .more-info img {
    position: absolute;
    left: 50px;
    bottom: 0px;
    width: 200px;
    display: none;
}

.what-we-do .tab-content .tab-description .col-md-4 img {
	vertical-align: top;
	max-width: 300px;
}

.pioneering .container-fluid .col-md-6 img {
    float: left;
    margin-left: -10%;
}

.hire-on-demond .container-fluid .col-md-6 img {
    float: right;
    margin-right: -10%;
}

@media only screen and (min-width: 1600px) and (max-width: 1920px) {

.carousel-caption {
	position: absolute;
	color: #fff;
	text-align: left;
	margin: 0% auto 0;
	padding-bottom: 0%;
	bottom: 35%;
	left: 12%;
}

.container-fluid {
  max-width: 100%;
  width: 100%;
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}
}
@media only screen and (min-width: 1280px) and (max-width: 1440.99px) {

  .what-we-do .nav-link {
    margin: 0 1.7rem;
  }

    .carousel-inner .d-block.mx-auto {
        width: 100% !important;
    }

    .carousel-caption[_ngcontent-ng-c464164484] {
        position: absolute;
        width: 605px;
        color: #fff;
        text-align: center;
        margin: 0% auto 0;
        padding-bottom: 0%;
        bottom: 160px;
        left: 45px;
        right: unset;
    }

    .serve_humanity_content .col-md-8 {
        flex: 0 0 auto;
        width: 80%;
    }

    .cybersecurity .cybersecurity-content h3 {
        width: 90%;
    }

    .serve_humanity {
        padding: 6.0% 0px 10.5%;
        margin: 0px auto;

        background-size: 105%;
    }

    .what-we-do .tab-bg {
        background-size: 95%;
    }

    .what-we-do .tab-content .more-info img {
        position: absolute;
        right: -105px;
        bottom: 20px;
        width: 200px;
        left: unset;
    }

    .hire-on-demond .hire-content h2 {
        width: 80%;
        max-width: 100%;
    }

    .pioneering .pioneering-content {
        margin: 0px 20% 0px 13%;
    }

    .driven-by-ai .bg-left {
        background-size: contain;
    }

    .cybersecurity .cybersecurity-content {
        margin: 7% 0px;
        padding-right: 0%;
    }

    .hire-on-demond .hire-content {
        margin: 0px 0px 0px 13%;
    }


}


@media only screen and (min-width: 992px) and (max-width: 1024.99px) {

  .what-we-do .nav-link {
    margin: 0 1rem;
  }

    .carousel-inner .d-block.mx-auto {
        width: 100% !important;
    }

    #carouselExampleCaptions {
        margin-top: 0px;
    }

    .carousel-caption[_ngcontent-ng-c464164484] {
        position: absolute;
        width: 605px;
        color: #fff;
        text-align: center;
        margin: 0% auto 0;
        padding-bottom: 0%;
        bottom: 160px;
        left: 45px;
        right: unset;
    }

    .carousel-item h1 {
        font-size: 33px;
        text-align: left;
        font-weight: 700;
        margin: 20px auto 0px;

        padding-bottom: 5px;
    }

    .carousel-item h2 {
        font-size: 30px;
        line-height: 30px;
        text-align: left;
        font-weight: 700;

        padding-bottom: 10px;
    }


    .serve_humanity_content .col-md-8 {
        flex: 0 0 auto;
        width: 80%;
    }

    .cybersecurity .cybersecurity-content h3 {
        width: 90%;
    }

    .cybersecurity {
        padding: 5% 0px 0%;
        margin: 0px auto;
        color: #fff;
    }

    .cybersecurity .cybersecurity-content {
        margin: 0% 0px;
        padding-right: 0%;
        padding-bottom: 3%;
    }

    .what-we-do .tab-bg {
      background-size: 95%;
    }


    .hire-on-demond .hire-content h2 {
        width: 95%;
        max-width: 100%;
    }

    .pioneering .pioneering-content {
        margin: 0px 10% 0px 10%;
    }

    .driven-by-ai .bg-left {

        background-size: contain;
    }

    .hire-on-demond .hire-content {
        margin: 0px 0px 0px 16%;
    }

    .what-we-do .tabs-container nav {
        max-width: 95%;
    }

    .what-we-do .tabs-container nav ul li {
        font-size: 15px;
    }

    .driven-by-ai .container h3 {
        width: 90%;
    }

    .what-we-do .tab-content .tab-description h3 {
      margin-bottom: 50px;
    }

    .what-we-do  .tab-content .mx-auto img {
      position: relative;
      bottom: 0px;
    }

}


@media only screen and (min-width: 768px) and (max-width: 991.99px) {
    body {
        font-family: Onest, sans-serif;
        line-height: 30px;
        font-size: 18px;
        overflow-x: hidden;
    }

    .carousel-inner .d-block.mx-auto {
        width: 100% !important;
    }

    #carouselExampleCaptions {
        margin-top: 0px;
    }

    .carousel-caption[_ngcontent-ng-c464164484] {
        position: absolute;
        width: 540px;
        color: #fff;
        text-align: center;
        margin: 0% auto 0px;
        padding-bottom: 0%;
        bottom: 60px;
        font-size: 15px;
        right: unset;
    }

    .carousel-caption h1 {
        font-size: 27px;
        line-height: 27px;
        margin-top: 0px;
    }

    .carousel-caption h2 {
        font-size: 24px;
        padding-bottom: 5px;
        line-height: 24px;
        margin-bottom: 0px;
    }

    .carousel-item p {
        text-align: left;
        font-size: 18px;
        line-height: 30px;
        font-weight: 300;
    }


    .serve_humanity .points img {
        width: 20px;
    }

    .serve_humanity_content .col-md-8 {
        flex: 0 0 auto;
        width: 80%;
    }

    .serve_humanity h2 {
        font-size: 24px;
        color: #333333;
        text-align: center;
        margin-bottom: 0px;
        font-family: 'gt_walsheimbold', sans-serif;
    }

    .what-we-do .container-lg {

        background-size: contain;
        padding: 0px;
        width: 90%;
    }

    .what-we-do h2 {
        color: #333333;
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        font-family: 'gt_walsheimbold', sans-serif;
    }

    .cybersecurity {
        padding: 5% 0px 0%;
        margin: 0px auto;
        color: #fff;
    }

    .cybersecurity .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        margin: 20px auto 0;
        text-align: center;
    }

    .cybersecurity .cybersecurity-content .col-md-8,
    .cybersecurity .cybersecurity-content .col-md-4 {
        flex: 0 0 auto;
        width: 100%;
    }

    .cybersecurity .col-md-6 img {
        max-width: 300px;
        margin: 20px auto 0px;
    }

    .cybersecurity .cybersecurity-content {
        margin: 0% auto;
        padding-right: 0%;
        padding-bottom: 3%;
        text-align: center;
    }

    .cybersecurity .cybersecurity-content h2 {
        font-size: 24px;
        color: #6dfaec;
        text-align: center;
        margin-bottom: 10px;
        font-weight: 700;
    }

    .cybersecurity .cybersecurity-content p {
        text-align: center;
        font-size: 16px;
        line-height: 30px;
        color: #fff;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .cybersecurity .cybersecurity-content .signup-form {
        width: 80%;
        margin: 0px auto;
    }

    .cybersecurity .cybersecurity-content .signup-form input {
        margin: 20px auto;
    }

    .cybersecurity .cybersecurity-content .secure-now {
        background: #6DFAEC;
        border-radius: 30px;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        text-decoration: none;
        padding: 12px 20px;
        color: #0f3828;
        font-weight: 700;
        border: solid 1px #6DFAEC;
        width: 100%;
        text-transform: uppercase;
    }

    .serve_humanity {
        padding: 6.0% 0px 10.5%;
        margin: 0px auto;
        background-size: 95%;
    }

    .what-we-do .tab-bg {

        background-size: 95%;
    }

    .hire-on-demond .hire-content h2 {
        width: 95%;
        max-width: 100%;
        text-align: center;
        font-size: 24px;
    }

    .hire-on-demond .hire-content h3 {
        width: 95%;
        max-width: 100%;
        text-align: center;
        font-size: 24px;
    }

    .hire-on-demond .hire-content p {
        text-align: center;
        font-size: 16px;
        line-height: 28px;

    }

    .hire-on-demond .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        margin: auto;
    }

    .hire-on-demond .container-fluid .col-md-6 img {
        float: none;
        margin: 20px auto;
        max-width: 80%;
        display: block;
    }

    .hire-on-demond .know-more {
        margin: 20px auto;
    }

    .pioneering .pioneering-content h2 {
        font-size: 24px;
        color: #333333;
        text-align: center;

    }

    .pioneering .container-fluid .col-md-6 img {
        float: none;
        margin: 20px auto;
        max-width: 80%;
        display: block;
    }

    .pioneering .pioneering-content p {
        text-align: center;
        font-size: 16px;
        line-height: 28px;
    }

    .pioneering .pioneering-content .know-more {
        margin: 20px auto;
    }


    .hire-on-demond .hire-content {
        margin: 0px 10% 0px;
    }


    .what-we-do .tabs-container nav {
        max-width: 95%;
    }

    .hire-on-demond .hire-content ul {
        margin: 3% 0px 3%;
        padding: 0px;
    }

    .what-we-do .tabs-container nav ul li {
        display: inline-block;
        margin: 0 8px 0;
        padding: 0 0 0.2rem 0;
        border: none;
        font-size: 13px;
    }

    .what-we-do .tab-content .mx-auto img {
      border-radius: 40px;
    }

    .what-we-do .tab-content img.mw-100 {
      max-width: 100% !important;
      height: 600px;
      background: #0066AA;
    }

    .what-we-do .tab-content .tab-description {
      text-align: left;
      color: #fff;
      position: absolute;
      top: 50px;
      left: 50px;
      width: 80%;
    }

    .what-we-do .tab-content .tab-description h3 {
        color: #fff;
        font-size: 24px;
        margin-bottom: 40px;
        font-weight: 700;
    }

    .what-we-do .tab-content .tab-description  .row .col-md-6 {
      flex: 0 0 auto;
      width: 100%;
    }
    .hire-on-demond .row .col-md-5,
    .hire-on-demond .row .col-md-7 {
        flex: 0 0 auto;
        width: 100%;
    }


    .pioneering .pioneering-content {
        margin: 0px 10% 0px 10%;
    }

    .pioneering .row .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
    }

    .driven-by-ai .row .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
    }

    .driven-by-ai .bg-left {

        background-size: 74px;
    }

    .driven-by-ai .row .col-md-6 .mw-100.float-end {
        float: unset !important;
        margin: 10px auto;
    }

    .driven-by-ai .container .card {
        width: 90%;
        border: solid 1 px #BAB5CE;
        border-radius: 20px;
        color: #333;
        text-align: left;
        padding: 3%;
        font-family: 'gt_walsheimbold', sans-serif;
        margin: 10px auto;
    }

    .driven-by-ai .container .card:nth-child(2) {
        margin: 20px auto;
    }

    .driven-by-ai .container .col-md-4.card.my-auto {
        margin: 20px auto !important;
    }

    .driven-by-ai .container h3 {
        width: 90%;
    }

    .what-we-do  .tab-content .mx-auto img {
      position: relative;
      bottom: 0px;
    }

}

@media only screen and (min-width: 560px) and (max-width: 767.99px) {
    body {
        font-family: Onest, sans-serif;
        line-height: 30px;
        font-size: 18px;
        overflow-x: hidden;
    }

    .carousel-inner {
        background: #050521;
        padding-top: 50px;
    }

    .carousel-inner .d-block.mx-auto {
        width: 100% !important;
    }

    #carouselExampleCaptions {
        margin-top: 0px;
    }


    .carousel-caption {
        position: absolute;
        width: 70%;
        color: #fff;
        text-align: center;
        margin: 0% auto 0px;
        padding-bottom: 0%;
        bottom: 15% !important;
        left: 30px;
        font-size: 15px;
        right: unset;
    }



    .carousel-caption h1 {
        font-size: 25px;
        line-height: 27px;
        margin-top: 0px;
    }

    .carousel-caption h2 {
        font-size: 22px;
        padding-bottom: 5px;
        line-height: 24px;
        margin-bottom: 0px;
    }

    .carousel-item p {
        text-align: left;
        font-size: 18px;
        line-height: 30px;
        font-weight: 300;
    }


    .serve_humanity .points img {
        width: 20px;
    }

    .serve_humanity_content .col-md-8 {
        flex: 0 0 auto;
        width: 80%;
    }

    .serve_humanity h2 {
        font-size: 24px;
        color: #333333;
        text-align: center;
        margin-bottom: 0px;
        font-family: 'gt_walsheimbold', sans-serif;
    }

    .what-we-do .container-lg {

        background-size: contain;
        padding: 0px;
        width: 90%;
    }

    .what-we-do .tab-content .mx-auto img {
      border-radius: 40px;
    }

    .what-we-do .tab-content img.mw-100 {
      max-width: 100% !important;
      height: 700px;
      background: #0066AA;
    }

    // .what-we-do .tab-content .tab-description {
    //     text-align: left;
    //     color: #fff;
    //     position: absolute;
    //     top: 30px;
    //     left: 35px;
    //     width: 80%;
    // }

    .what-we-do .tab-content .tab-description {
      text-align: left;
      color: #fff;
      position: absolute;
      top: 80px;
      left: 70px;
      width: 70%;
    }

    .what-we-do h2 {
        color: #333333;
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        font-family: 'gt_walsheimbold', sans-serif;
    }

    .cybersecurity {
        padding: 5% 0px 0%;
        margin: 0px auto;
        color: #fff;
    }

    .cybersecurity .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        margin: 20px auto 0;
        text-align: center;
    }

    .cybersecurity .cybersecurity-content .col-md-8,
    .cybersecurity .cybersecurity-content .col-md-4 {
        flex: 0 0 auto;
        width: 100%;
    }

    .cybersecurity .col-md-6 img {
        max-width: 200px;
        margin: 20px auto 0px;
    }

    .cybersecurity .cybersecurity-content {
        margin: 0% auto;
        padding-right: 0%;
        padding-bottom: 3%;
        text-align: center;
    }

    .cybersecurity .cybersecurity-content h2 {
        font-size: 24px;
        color: #6dfaec;
        text-align: center;
        margin-bottom: 10px;
        font-weight: 700;
    }

    .cybersecurity .cybersecurity-content p {
        text-align: center;
        font-size: 16px;
        line-height: 30px;
        color: #fff;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .cybersecurity .cybersecurity-content .signup-form {
        width: 80%;
        margin: 0px auto;
    }

    .cybersecurity .cybersecurity-content .signup-form input {
        margin: 20px auto;
    }

    .cybersecurity .cybersecurity-content .secure-now {
        background: #6DFAEC;
        border-radius: 30px;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        text-decoration: none;
        padding: 12px 20px;
        color: #0f3828;
        font-weight: 700;
        border: solid 1px #6DFAEC;
        width: 100%;
        text-transform: uppercase;
    }

    .serve_humanity {
        padding: 6.0% 0px 10.5%;
        margin: 0px auto;
        background-size: 95%;
    }

    .what-we-do .tab-bg {

        background-size: 95%;
    }

    .hire-on-demond .hire-content h2 {
        width: 95%;
        max-width: 100%;
        text-align: center;
        font-size: 24px;
    }

    .hire-on-demond .hire-content h3 {
        width: 95%;
        max-width: 100%;
        text-align: center;
        font-size: 24px;
    }

    .hire-on-demond .hire-content p {
        text-align: center;
        font-size: 16px;
        line-height: 28px;

    }

    .hire-on-demond .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        margin: auto;
    }

    .hire-on-demond .container-fluid .col-md-6 img {
        float: none;
        margin: 20px auto;
        max-width: 80%;
        display: block;
    }

    .hire-on-demond .know-more {
        margin: 20px auto;
    }

    .pioneering .pioneering-content h2 {
        font-size: 24px;
        color: #333333;
        text-align: center;

    }

    .pioneering .container-fluid .col-md-6 img {
        float: none;
        margin: 20px auto;
        max-width: 80%;
        display: block;
    }

    .pioneering .pioneering-content p {
        text-align: center;
        font-size: 16px;
        line-height: 28px;
    }

    .pioneering .pioneering-content .know-more {
        margin: 20px auto;
    }


    .hire-on-demond .hire-content {
        margin: 0px 10% 0px;
    }


    .what-we-do .tabs-container nav {
        max-width: 95%;
    }

    .hire-on-demond .hire-content ul {
        margin: 3% 0px 3%;
        padding: 0px;
    }

    .what-we-do .tabs-container nav ul li {
        display: inline-block;
        margin: 0 8px 0;
        padding: 0 0 0.2rem 0;
        border: none;
        font-size: 13px;
    }

    .hire-on-demond .row .col-md-5,
    .hire-on-demond .row .col-md-7 {
        flex: 0 0 auto;
        width: 100%;
    }

    .what-we-do .tab-content .tab-description h3 {
        color: #fff;
        font-size: 24px;
        margin-bottom: 30px;
        font-weight: 700;
    }

    .pioneering .pioneering-content {
        margin: 0px 10% 0px 10%;
    }

    .pioneering .row .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
    }

    .driven-by-ai .row .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
    }

    .driven-by-ai .bg-left {

        background-size: 74px;
    }

    .driven-by-ai .row .col-md-6 .mw-100.float-end {
        float: unset !important;
        margin: 10px auto;
    }

    .driven-by-ai .container .card {
        width: 90%;
        border: solid 1 px #BAB5CE;
        border-radius: 20px;
        color: #333;
        text-align: left;
        padding: 3%;
        font-family: 'gt_walsheimbold', sans-serif;
        margin: 10px auto;
    }

    .driven-by-ai .container .card:nth-child(2) {
        margin: 20px auto;
    }

    .driven-by-ai .container .col-md-4.card.my-auto {
        margin: 20px auto !important;
    }

    .driven-by-ai .container h3 {
        width: 90%;
    }

    .what-we-do  .tab-content .mx-auto img {
      position: relative;
      bottom: 0px;
    }
}


@media only screen and (min-width: 460px) and (max-width: 559.99px) {

    .carousel-inner {
        background: #050521;
        padding-top: 50px;
    }

    .carousel-inner .d-block.mx-auto {
        width: 100% !important;
    }

    #carouselExampleCaptions {
        margin-top: 0px;
    }

    .carousel-caption {
        position: absolute;
        width: 70%;
        color: #fff;
        text-align: center;
        margin: 0% auto 0px;
        padding-bottom: 0%;
        bottom: 15% !important;
        left: 30px;
        font-size: 15px;
        right: unset;
    }

    .carousel-caption h1 {
        font-size: 25px;
        line-height: 27px;
        margin-top: 0px;
    }

    .carousel-caption h2 {
        font-size: 22px;
        padding-bottom: 5px;
        line-height: 24px;
        margin-bottom: 0px;
    }

    .carousel-item p {
        text-align: left;
        font-size: 16px;
        line-height: 26px;
        font-weight: 300;
    }

    .serve_humanity .points img {
        width: 18px;
    }

    .serve_humanity_content .col-md-8 {
        flex: 0 0 auto;
        width: 80%;
    }

    .serve_humanity h2 {
        font-size: 24px;
        color: #333333;
        text-align: center;
        margin-bottom: 0px;
    }

    .serve_humanity h2 br {
        display: none;
    }

    .serve_humanity h2 span {
        display: block;
    }

    .what-we-do .container-lg {
        background-size: contain;
        padding: 0px;
        width: 90%;
    }



    // .what-we-do .tab-content .tab-description {
    //     text-align: left;
    //     color: #fff;
    //     position: absolute;
    //     top:30px;
    //     left: 35px;
    //     width: 80%;
    // }

    .what-we-do .tab-content .tab-description {
      text-align: left;
      color: #fff;
      position: absolute;
      top: 80px;
      left: 70px;
      width: 80%;
    }

    .what-we-do h2 {
        color: #333333;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        font-weight: 600;
        font-family: 'gt_walsheimbold', sans-serif;
        margin-bottom: 5px;
    }

    .what-we-do h2 br {
        display: none;
    }

    .what-we-do h2 span {
        display: block;
    }

    .what-we-do .tab-content .tab-description p {
        font-size: 15px;
        line-height: 20px;
        width: 100%;
        color: #fff;
    }

    .cybersecurity {
        padding: 5% 0px 0%;
        margin: 0px auto;
        color: #fff;
    }

    .cybersecurity .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        margin: 20px auto 0;
        text-align: center;
    }

    .cybersecurity .cybersecurity-content .col-md-8,
    .cybersecurity .cybersecurity-content .col-md-4 {
        flex: 0 0 auto;
        width: 100%;
    }

    .cybersecurity .col-md-6 img {
        max-width: 200px;
        margin: 20px auto 0px;
    }

    .cybersecurity .cybersecurity-content {
        margin: 0% auto;
        padding-right: 0%;
        padding-bottom: 3%;
        text-align: center;
    }

    .cybersecurity .cybersecurity-content h2 {
        font-size: 24px;
        line-height: 34px;
        color: #6dfaec;
        text-align: center;
        margin-bottom: 10px;
        font-weight: 700;
    }

    .cybersecurity .cybersecurity-content p {
        text-align: center;
        font-size: 16px;
        line-height: 30px;
        color: #fff;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .cybersecurity .cybersecurity-content .signup-form {
        width: 80%;
        margin: 0px auto;
    }

    .cybersecurity .cybersecurity-content .signup-form input {
        margin: 20px auto;
    }

    .cybersecurity .cybersecurity-content .secure-now {
        background: #6DFAEC;
        border-radius: 30px;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        text-decoration: none;
        padding: 12px 20px;
        color: #0f3828;
        font-weight: 700;
        border: solid 1px #6DFAEC;
        width: 100%;
        text-transform: uppercase;
    }

    .serve_humanity {
        padding: 6.0% 0px 10.5%;
        margin: 0px auto;
        background-size: 95%;
    }

    .serve_humanity .points {
        background: #fff;
        width: 80%;
        height: 60px;
        line-height: 40px;
        text-align: center;
        padding: 10px;
        margin: 10px auto;
        color: #333;
        border: solid 2px #03DAC4;
        border-radius: 50px;
    }

    .what-we-do .tab-bg {

        background-size: 95%;
    }

    .hire-on-demond .hire-content h2 {
        width: 95%;
        max-width: 100%;
        text-align: center;
        font-size: 24px;
        line-height: 34px;
    }

    .hire-on-demond .hire-content h3 {
        width: 95%;
        max-width: 100%;
        text-align: center;
        font-size: 24px;
        line-height: 34px;
    }

    .hire-on-demond .hire-content p {
        text-align: center;
        font-size: 16px;
        line-height: 28px;

    }

    .hire-on-demond .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        margin: auto;
    }

    .hire-on-demond .container-fluid .col-md-6 img {
        float: none;
        margin: 20px auto;
        max-width: 80%;
        display: block;
    }

    .hire-on-demond .know-more {
        margin: 20px auto;
    }

    .pioneering .pioneering-content h2 {
        font-size: 24px;
        color: #333333;
        text-align: center;

    }

    .pioneering .container-fluid .col-md-6 img {
        float: none;
        margin: 20px auto;
        max-width: 80%;
        display: block;
    }

    .pioneering .pioneering-content p {
        text-align: center;
        font-size: 16px;
        line-height: 28px;
    }

    .pioneering .pioneering-content .know-more {
        margin: 20px auto;
    }


    .hire-on-demond .hire-content {
        margin: 0px 10% 0px;
    }


    .what-we-do .tabs-container nav {
        max-width: 95%;
    }

    .hire-on-demond .hire-content ul {
        margin: 3% 0px 3%;
        padding: 0px;
    }

    .what-we-do .tabs-container nav ul li {
        display: inline-block;
        margin: 0 8px 0;
        padding: 0 0 0.2rem 0;
        border: none;
        font-size: 13px;
    }

    .what-we-do .tab-content .mx-auto img {
      border-radius: 40px;
    }

    .what-we-do .tab-content img.mw-100 {
      max-width: 100% !important;
      height: 700px;
      background: #0066AA;
    }

    .what-we-do .tab-content .tab-description h3 {
        color: #fff;
        font-size: 24px;
        margin-bottom: 30px;
        font-weight: 700;
    }

    .hire-on-demond .row .col-md-5,
    .hire-on-demond .row .col-md-7 {
        flex: 0 0 auto;
        width: 100%;
    }


    .pioneering .pioneering-content {
        margin: 0px 10% 0px 10%;
    }

    .pioneering .row .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
    }

    .driven-by-ai .row .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
    }

    .driven-by-ai .bg-left {

        background-size: 74px;
    }

    .driven-by-ai .row .col-md-6 .mw-100.float-end {
        float: unset !important;
        margin: 10px auto;
    }

    .driven-by-ai .container .card {
        width: 90%;
        border: solid 1 px #BAB5CE;
        border-radius: 20px;
        color: #333;
        text-align: left;
        padding: 3%;
        font-family: 'gt_walsheimbold', sans-serif;
        margin: 10px auto;
    }

    .driven-by-ai .container .card:nth-child(2) {
        margin: 20px auto;
    }

    .driven-by-ai .container .col-md-4.card.my-auto {
        margin: 20px auto !important;
    }

    .driven-by-ai .container h3 {
        width: 90%;
    }

    .what-we-do  .tab-content .mx-auto img {
      position: relative;
      bottom: 0px;
    }
}

@media only screen and (min-width: 360px) and (max-width: 459.99px) {

    .carousel-inner {
        background: #050521;
        padding-top: 50px;
    }

    .carousel-inner .d-block.mx-auto {
        width: 100% !important;
    }

    #carouselExampleCaptions {
        margin-top: 0px;
    }

    .carousel-caption {
        position: absolute;
        width: 90%;
        color: #fff;
        text-align: center;
        margin: 0% auto 0px;
        padding-bottom: 0%;
        bottom: 5% !important;
        left: 10px;
        font-size: 15px;
        right: unset;
    }

    .carousel-caption h1 {
        font-size: 23px;
        line-height: 23px;
        margin-top: 0px;
    }

    .carousel-caption h2 {
        font-size: 20px;
        padding-bottom: 5px;
        line-height: 20px;
        margin-bottom: 0px;
    }

    .carousel-item p {
        text-align: left;
        font-size: 16px;
        line-height: 16px;
        font-weight: 300;
    }

    .serve_humanity .points img {
        width: 18px;
    }

    .serve_humanity_content .col-md-8 {
        flex: 0 0 auto;
        width: 80%;
    }

    .serve_humanity h2 {
        font-size: 20px;
        color: #333333;
        text-align: center;
        margin-bottom: 0px;
    }

    .serve_humanity h2 br {
        display: none;
    }

    .serve_humanity h2 span {
        display: block;
    }

    .what-we-do .container-lg {
        background-size: contain;
        padding: 0px;
        width: 90%;
    }

    .what-we-do .p-component, .what-we-do .p-component * {
      box-sizing: border-box;
    }
    .what-we-do .tab-content .mx-auto img {
      border-radius: 40px;
    }

    .what-we-do .tab-content img.mw-100 {
      max-width: 100% !important;
      height: 700px;
      background: #0066AA;
    }

    // .what-we-do .tab-content .tab-description {
    //     text-align: left;
    //     color: #fff;
    //     position: absolute;
    //     top: 30px;
    //     left: 35px;
    //     width: 100%;
    // }

    .what-we-do .tab-content .tab-description {
      text-align: left;
      color: #fff;
      position: absolute;
      top: 80px;
      left: 55px;
      width: 80%;
    }

    .what-we-do .tab-content .tab-description h3 {
        color: #fff;
        font-size: 20px;
        margin-bottom: 30px;
        font-weight: 700;
    }

    .what-we-do h2 {
        color: #333333;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 5px;
        font-weight: 600;
    }

    .what-we-do h2 br {
        display: none;
    }

    .what-we-do h2 span {
        display: block;
    }

    .what-we-do .tab-content .tab-description p {
        font-size: 14px;
        line-height: 18px;
        width: 80%;
        clear: both;
    }

    .cybersecurity {
        padding: 5% 0px 0%;
        margin: 0px auto;
        color: #fff;
    }

    .cybersecurity .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        margin: 20px auto 0;
        text-align: center;
    }

    .cybersecurity .cybersecurity-content .col-md-8,
    .cybersecurity .cybersecurity-content .col-md-4 {
        flex: 0 0 auto;
        width: 100%;
    }

    .cybersecurity .col-md-6 img {
        max-width: 200px;
        margin: 20px auto 0px;
    }

    .cybersecurity .cybersecurity-content {
        margin: 0% auto;
        padding-right: 0%;
        padding-bottom: 3%;
        text-align: center;
    }

    .cybersecurity .cybersecurity-content h2 {
        font-size: 20px;
        line-height: 34px;
        color: #6dfaec;
        text-align: center;
        margin-bottom: 10px;
        font-weight: 700;
    }

    .cybersecurity .cybersecurity-content p {
        text-align: center;
        font-size: 16px;
        line-height: 30px;
        color: #fff;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .cybersecurity .cybersecurity-content .signup-form {
        width: 80%;
        margin: 0px auto;
    }

    .cybersecurity .cybersecurity-content .signup-form input {
        margin: 20px auto;
    }

    .cybersecurity .cybersecurity-content .secure-now {
        background: #6DFAEC;
        border-radius: 30px;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        text-decoration: none;
        padding: 12px 20px;
        color: #0f3828;
        font-weight: 700;
        border: solid 1px #6DFAEC;
        width: 100%;
        text-transform: uppercase;
    }

    .serve_humanity {
        padding: 6.0% 0px 10.5%;
        margin: 0px auto;
        background-size: 95%;
    }

    .serve_humanity .points {
        background: #fff;
        width: 200px;
        height: 60px;
        line-height: 40px;
        text-align: center;
        padding: 10px;
        margin: 10px auto;
        color: #333;
        border: solid 2px #03DAC4;
        border-radius: 50px;
    }

    .what-we-do .tab-bg {

        background-size: 95%;
    }

    .hire-on-demond .hire-content h2 {
        width: 95%;
        max-width: 100%;
        text-align: center;
        font-size: 20px;
        line-height: 34px;
    }

    .hire-on-demond .hire-content h3 {
        width: 95%;
        max-width: 100%;
        text-align: center;
        font-size: 20px;
        line-height: 34px;
    }

    .hire-on-demond .hire-content p {
        text-align: center;
        font-size: 16px;
        line-height: 28px;

    }

    .hire-on-demond .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        margin: auto;
    }

    .hire-on-demond .container-fluid .col-md-6 img {
        float: none;
        margin: 20px auto;
        max-width: 80%;
        display: block;
    }

    .hire-on-demond .know-more {
        margin: 20px auto;
    }

    .pioneering .pioneering-content h2 {
        font-size: 20px;
        color: #333333;
        text-align: center;

    }

    .pioneering .container-fluid .col-md-6 img {
        float: none;
        margin: 20px auto;
        max-width: 80%;
        display: block;
    }

    .pioneering .pioneering-content p {
        text-align: center;
        font-size: 16px;
        line-height: 28px;
    }

    .pioneering .pioneering-content .know-more {
        margin: 20px auto;
    }


    .hire-on-demond .hire-content {
        margin: 0px 10% 0px;
    }


    .what-we-do .tabs-container nav {
        max-width: 95%;
    }

    .hire-on-demond .hire-content ul {
        margin: 3% 0px 3%;
        padding: 0px;
    }

    .what-we-do .tabs-container nav ul li {
        display: inline-block;
        margin: 0 8px 0;
        padding: 0 0 0.2rem 0;
        border: none;
        font-size: 13px;
    }

    .hire-on-demond .row .col-md-5,
    .hire-on-demond .row .col-md-7 {
        flex: 0 0 auto;
        width: 100%;
    }


    .pioneering .pioneering-content {
        margin: 0px 10% 0px 10%;
    }

    .pioneering .row .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
    }

    .driven-by-ai .row .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
    }

    .driven-by-ai .bg-left {

        background-size: 74px;
    }

    .driven-by-ai .row .col-md-6 .mw-100.float-end {
        float: unset !important;
        margin: 10px auto;
    }

    .driven-by-ai .container .card {
        width: 90%;
        border: solid 1 px #BAB5CE;
        border-radius: 20px;
        color: #333;
        text-align: left;
        padding: 3%;
        font-family: 'gt_walsheimbold', sans-serif;
        margin: 10px auto;
    }

    .driven-by-ai .container .card:nth-child(2) {
        margin: 20px auto;
    }

    .driven-by-ai .container .col-md-4.card.my-auto {
        margin: 20px auto !important;
    }

    .driven-by-ai .container h3 {
        width: 90%;
    }

    .what-we-do  .tab-content .mx-auto img {
      position: relative;
      bottom: 0px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 359.99px) {

    .carousel-inner {
        background: #050521;
        padding-top: 50px;
    }

    .carousel-inner .d-block.mx-auto {
        width: 100% !important;
    }

    #carouselExampleCaptions {
        margin-top: 0px;
    }

    .carousel-caption {
        position: absolute;
        width: 80%;
        color: #fff;
        text-align: center;
        margin: 0% auto 0px;
        padding-bottom: 0%;
        bottom: 5% !important;
        left: 10px;
        font-size: 15px;
        right: unset;
    }

    .carousel-caption h1 {
        font-size: 20px;
        line-height: 20px;
        margin-top: 0px;
    }

    .carousel-caption h2 {
        font-size: 20px;
        padding-bottom: 5px;
        line-height: 20px;
        margin-bottom: 0px;
    }

    .carousel-item p {
        text-align: left;
        font-size: 15px;
        line-height: 20px;
        font-weight: 300;
    }

    .serve_humanity .points img {
        width: 18px;
    }

    .serve_humanity_content .col-md-8 {
        flex: 0 0 auto;
        width: 80%;
    }

    .serve_humanity h2 {
        font-size: 20px;
        color: #333333;
        text-align: center;
        margin-bottom: 0px;
    }

    .serve_humanity h2 br {
        display: none;
    }

    .serve_humanity h2 span {
        display: block;
    }

    .what-we-do .container-lg {
        background-size: contain;
        padding: 0px;
        width: 90%;
    }

    .what-we-do .tab-content .mx-auto img {
      border-radius: 40px;
    }

    .what-we-do .tab-content img.mw-100 {
      max-width: 100% !important;
      height: 700px;
      background: #0066AA;
    }

    .what-we-do .tab-content .tab-description {
      text-align: left;
      color: #fff;
      position: absolute;
      top: 80px;
      left:40px;
      width: 80%;
    }

    .what-we-do h2 {
        color: #333333;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 5px;
        font-weight: 600;
    }

  //   .what-we-do h2 {
  //     color: #333333;
  //     font-size: 20px;
  //     line-height: 20px;
  //     text-align: center;
  //     margin-bottom: 5px;
  //     font-weight: 600;
  // }

    .what-we-do h2 br {
        display: none;
    }

    .what-we-do h2 span {
        display: block;
    }

    .what-we-do .tab-content .tab-description p {
        font-size: 15px;
        line-height: 20px;
        width: 100%;
    }

    .cybersecurity {
        padding: 5% 0px 0%;
        margin: 0px auto;
        color: #fff;
    }

    .cybersecurity .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        margin: 20px auto 0;
        text-align: center;
    }

    .cybersecurity .cybersecurity-content .col-md-8,
    .cybersecurity .cybersecurity-content .col-md-4 {
        flex: 0 0 auto;
        width: 100%;
    }

    .cybersecurity .col-md-6 img {
        max-width: 200px;
        margin: 20px auto 0px;
    }

    .cybersecurity .cybersecurity-content {
        margin: 0% auto;
        padding-right: 0%;
        padding-bottom: 3%;
        text-align: center;
    }

    .cybersecurity .cybersecurity-content h2 {
        font-size: 20px;
        line-height: 34px;
        color: #6dfaec;
        text-align: center;
        margin-bottom: 10px;
        font-weight: 700;
    }

    .cybersecurity .cybersecurity-content p {
        text-align: center;
        font-size: 16px;
        line-height: 30px;
        color: #fff;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .cybersecurity .cybersecurity-content .signup-form {
        width: 80%;
        margin: 0px auto;
    }

    .cybersecurity .cybersecurity-content .signup-form input {
        margin: 20px auto;
    }

    .cybersecurity .cybersecurity-content .secure-now {
        background: #6DFAEC;
        border-radius: 30px;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        text-decoration: none;
        padding: 12px 20px;
        color: #0f3828;
        font-weight: 700;
        border: solid 1px #6DFAEC;
        width: 100%;
        text-transform: uppercase;
    }

    .serve_humanity {
        padding: 6.0% 0px 10.5%;
        margin: 0px auto;
        background-size: 95%;
    }

    .serve_humanity .points {
        background: #fff;
        width: 200px;
        height: 60px;
        line-height: 40px;
        text-align: center;
        padding: 10px;
        margin: 10px auto;
        color: #333;
        border: solid 2px #03DAC4;
        border-radius: 50px;
    }

    .what-we-do .tab-bg {

        background-size: 95%;
    }

    .hire-on-demond .hire-content h2 {
        width: 95%;
        max-width: 100%;
        text-align: center;
        font-size: 20px;
        line-height: 34px;
    }

    .hire-on-demond .hire-content h3 {
        width: 95%;
        max-width: 100%;
        text-align: center;
        font-size: 20px;
        line-height: 34px;
    }

    .hire-on-demond .hire-content p {
        text-align: center;
        font-size: 16px;
        line-height: 28px;

    }

    .hire-on-demond .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
        margin: auto;
    }

    .hire-on-demond .container-fluid .col-md-6 img {
        float: none;
        margin: 20px auto;
        max-width: 80%;
        display: block;
    }

    .hire-on-demond .know-more {
        margin: 20px auto;
    }

    .pioneering .pioneering-content h2 {
        font-size: 20px;
        color: #333333;
        text-align: center;

    }

    .pioneering .container-fluid .col-md-6 img {
        float: none;
        margin: 20px auto;
        max-width: 80%;
        display: block;
    }

    .pioneering .pioneering-content p {
        text-align: center;
        font-size: 16px;
        line-height: 28px;
    }

    .pioneering .pioneering-content .know-more {
        margin: 20px auto;
    }


    .hire-on-demond .hire-content {
        margin: 0px 10% 0px;
    }


    .what-we-do .tabs-container nav {
        max-width: 95%;
    }

    .hire-on-demond .hire-content ul {
        margin: 3% 0px 3%;
        padding: 0px;
    }

    .what-we-do .tabs-container nav ul li {
        display: inline-block;
        margin: 0 8px 0;
        padding: 0 0 0.2rem 0;
        border: none;
        font-size: 13px;
    }

    .what-we-do .tab-content .tab-description h3 {
        color: #fff;
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: 700;
    }

    .hire-on-demond .row .col-md-5,
    .hire-on-demond .row .col-md-7 {
        flex: 0 0 auto;
        width: 100%;
    }

    .pioneering .pioneering-content {
        margin: 0px 10% 0px 10%;
    }

    .pioneering .row .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
    }

    .driven-by-ai .row .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
    }

    .driven-by-ai .bg-left {

        background-size: 74px;
    }

    .driven-by-ai .row .col-md-6 .mw-100.float-end {
        float: unset !important;
        margin: 10px auto;
    }

    .driven-by-ai .container .card {
        width: 90%;
        border: solid 1 px #BAB5CE;
        border-radius: 20px;
        color: #333;
        text-align: left;
        padding: 3%;
        font-family: 'gt_walsheimbold', sans-serif;
        margin: 10px auto;
    }

    .driven-by-ai .container .card:nth-child(2) {
        margin: 20px auto;
    }

    .driven-by-ai .container .col-md-4.card.my-auto {
        margin: 20px auto !important;
    }

    .driven-by-ai .container h3 {
        width: 90%;
    }

    .what-we-do  .tab-content .mx-auto img {
      position: relative;
      bottom: 0px;
    }
}

.device-banner {
  background: url(../assets/img/devices/banner-devices.png) no-repeat center top;
  max-height: 800px;
  padding: 25% 0px 25%;
}

.banner-device-content {
  text-align: left;
  color: #fff;
  padding: 0px 0px;
  width: 50%;
  float: left;
  margin-left: 0%;
  margin-top: 10%;
}

.banner-device-content h1 {
  font-size: 45px;
  line-height: 45px;
  margin-bottom: 20px;
}

.banner-device-content h2 {
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 20px;
}

.banner-device-content p {
  font-size: 24px;
  line-height: 34px;
}

.banner-device-content {
  text-align: left;
  color: #fff;
  padding: 0px 0px;
  width: 50%;
  float: left;
  margin-left: 0%;
  margin-top: 10%;
}

.banner-device-content h1 {
  font-size: 45px;
  line-height: 45px;
  margin-bottom: 20px;
}

.banner-device-content p {
  font-size: 24px;
  line-height: 34px;
}

.devices {
  padding: 5% 0px 5%;
  background: #F4F9FC;
}

.devices h2 {
  text-align: center;
  font-size: 33px;
  line-height: 43px;
  margin-bottom: 30px;
}

.devices-bg {
  background: #F4F9FC;
}

.devices .card {
  border: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  margin: 0px auto 8%;
  max-width: 400px;
  height: 378px;
  padding: 25px;
}

.devices .card .ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 93px;
  height: 93px;
  text-align: right;
}

.devices .card .ribbon span {
  font-size: 0.8rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 32px;
  transform: rotate(45deg);
  width: 125px;
  display: block;
  background: #03DAC4;
  background: linear-gradient(#03CBB6 0%, #03DAC4 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 17px;
  right: -29px;
}

.devices .card .ribbon span::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #03DAC4;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #03DAC4;
}

.devices .card .ribbon span::after {
  content: '';
  position: absolute;
  right: 0%;
  top: 100%;
  z-index: -1;
  border-right: 3px solid #03DAC4;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #03DAC4;
}

.devices .card .device-thumb {
  margin: 10px auto;
  height: 350px;
}

.devices .card .card-title {
  font-size: 1.4rem;
  margin: 10px 0px;
  text-align: center;
}

.devices .card button.button-more_info {
  width: 198px;
  height: 57px;
  margin: auto;
  background: #03DAC4;
  border: none;
  border-radius: 29px;
  color: #fff;
  text-align: center;
  line-height: 57px;
  text-transform: uppercase;
  font-weight: 700;
}

.devices .card button.button-more_info:hover {
  width: 198px;
  height: 57px;
  margin: auto;
  background: #0F3828;
  border: none;
  border-radius: 29px;
  color: #fff;
  text-align: center;
  line-height: 57px;
  text-transform: uppercase;
  font-weight: 700;
  transition: 0.3s;
}

.devices .card button.button-more_info a {
  text-decoration: none;
  color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1024.99px) {
  .devices .card .device-thumb {
    margin: 10px auto;
    height: 300px;
  }

  .devices .card .card-title {
    font-size: 1.3rem;
  }

  .devices .card button.button-more_info {
    width: 198px;
    height: 45px;
    margin: auto;
    background: #03DAC4;
    border: none;
    border-radius: 29px;
    color: #fff;
    text-align: center;
    line-height: 45px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
  }

  .banner-device-content h1 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .banner-device-content p {
    font-size: 20px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.99px) {
  .devices .card .device-thumb {
    margin: 10px auto;
    height: 300px;
  }

  .devices .card .card-title {
    font-size: 1.3rem;
  }

  .devices .card button.button-more_info {
    width: 198px;
    height: 45px;
    margin: auto;
    background: #03DAC4;
    border: none;
    border-radius: 29px;
    color: #fff;
    text-align: center;
    line-height: 45px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
  }

  .devices .col-md-4 {
    flex: 0 0 auto;
    width: 50%;
  }

  .banner-device-content h1 {
    font-size: 38px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .banner-device-content p {
    font-size: 18px;
    line-height: 30px;
  }

  .device-banner {
    padding: 25% 0;
    background-size: contain;
  }

  .banner-device-content {
    text-align: left;
    color: #fff;
    padding: 0;
    width: 80%;
    float: left;
    margin-left: 0%;
    margin-top: 2%;
  }
}

@media only screen and (min-width: 560px) and (max-width: 767.99px) {
  .devices .card .device-thumb {
    margin: 10px auto;
    height: 300px;
  }

  .devices .card .card-title {
    font-size: 1.3rem;
  }

  .devices .card button.button-more_info {
    width: 198px;
    height: 45px;
    margin: auto;
    background: #03DAC4;
    border: none;
    border-radius: 29px;
    color: #fff;
    text-align: center;
    line-height: 45px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
  }

  .banner-device-content h1 {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .banner-device-content p {
    font-size: 16px;
    line-height: 30px;
  }

  .device-banner {
    padding: 25% 0;
    height: 500px;
  }

  .banner-device-content {
    text-align: left;
    color: #fff;
    padding: 0;
    width: 80%;
    float: left;
    margin-left: 0%;
    margin-top: 40%;
  }

  .devices h2 {
    text-align: center;
    font-size: 30px;
    line-height: 33px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 460px) and (max-width: 559.99px) {
  .devices .card .device-thumb {
    margin: 10px auto;
    height: 300px;
  }

  .devices .card .card-title {
    font-size: 1.3rem;
  }

  .devices .card button.button-more_info {
    width: 198px;
    height: 45px;
    margin: auto;
    background: #03DAC4;
    border: none;
    border-radius: 29px;
    color: #fff;
    text-align: center;
    line-height: 45px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
  }

  .banner-device-content h1 {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .banner-device-content p {
    font-size: 16px;
    line-height: 30px;
  }

  .device-banner {
    padding: 25% 0;
    height: 500px;
  }

  .banner-device-content {
    text-align: left;
    color: #fff;
    padding: 0;
    width: 80%;
    float: left;
    margin-left: 0%;
    margin-top: 40%;
  }

  .devices h2 {
    text-align: center;
    font-size: 32px;
    line-height: 33px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 459.99px) {
  .devices .card .device-thumb {
    margin: 10px auto;
    height: 300px;
  }

  .devices .card .card-title {
    font-size: 1.3rem;
  }

  .devices .card button.button-more_info {
    width: 198px;
    height: 45px;
    margin: auto;
    background: #03DAC4;
    border: none;
    border-radius: 29px;
    color: #fff;
    text-align: center;
    line-height: 45px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
  }

  .banner-device-content h1 {
    font-size: 33px;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .banner-device-content p {
    font-size: 16px;
    line-height: 30px;
  }

  .device-banner {
    padding: 25% 0;
    height: 400px;
  }

  .banner-device-content {
    text-align: left;
    color: #fff;
    padding: 0;
    width: 80%;
    float: left;
    margin-left: 0%;
    margin-top: 15%;
  }

  .devices h2 {
    text-align: center;
    font-size: 30px;
    line-height: 33px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 359.99px) {
  .devices .card .device-thumb {
    margin: 10px auto;
    height: 300px;
  }

  .devices .card .card-title {
    font-size: 1.3rem;
  }

  .devices .card button.button-more_info {
    width: 198px;
    height: 45px;
    margin: auto;
    background: #03DAC4;
    border: none;
    border-radius: 29px;
    color: #fff;
    text-align: center;
    line-height: 45px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
  }

  .banner-device-content h1 {
    font-size: 33px;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .banner-device-content p {
    font-size: 16px;
    line-height: 30px;
  }

  .device-banner {
    padding: 25% 0;
    height: 400px;
  }

  .banner-device-content {
    text-align: left;
    color: #fff;
    padding: 0;
    width: 80%;
    float: left;
    margin-left: 0%;
    margin-top: 10%;
  }

  .devices h2 {
    text-align: center;
    font-size: 30px;
    line-height: 33px;
    margin-bottom: 30px;
  }
}

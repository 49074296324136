/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 5, 2020 */



@font-face {
    font-family: 'gt_walsheimblack';
    src: url('gt-walsheim-black-webfont.woff2') format('woff2'),
         url('gt-walsheim-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gt_walsheimbold';
    src: url('gt-walsheim-bold-webfont.woff2') format('woff2'),
         url('gt-walsheim-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gt_walsheimlight';
    src: url('gt-walsheim-light-webfont.woff2') format('woff2'),
         url('gt-walsheim-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gt_walsheimmedium';
    src: url('gt-walsheim-medium-webfont.woff2') format('woff2'),
         url('gt-walsheim-medium-webfont.woff') format('woff');
    font-weight:300;
    font-style: normal;

}




@font-face {
    font-family: 'gt_walsheim_regularregular';
    src: url('gt-walsheim-regular-webfont.woff2') format('woff2'),
         url('gt-walsheim-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gt_walsheimthin';
    src: url('gt-walsheim-thin-webfont.woff2') format('woff2'),
         url('gt-walsheim-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gt_walsheimultra_bold';
    src: url('gt-walsheim-ultra-bold-webfont.woff2') format('woff2'),
         url('gt-walsheim-ultra-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gt_walsheimultra_light';
    src: url('gt-walsheim-ultra-light-webfont.woff2') format('woff2'),
         url('gt-walsheim-ultra-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}